import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import NotFoundFullScreen from "../../components/NotFoundFullScreen/NotFoundFullScreen";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../services/axiosService";
import { PropertyInterface } from "../../services/properties/getProperties";
import { getPropertyById } from "../../services/properties/getPropertyById";
import MapBootstrap from "./containers/MapBootstrap";
import PropriedadeForm from "./PropriedadeForm";

interface PropriedadeCrudPageInterface {}

const CustomTableGrid = styled(Grid)`
  .MuiPaper-root {
    height: 100%;
    display: grid;
  }
`;

const PropriedadeCrudPage: React.FC<PropriedadeCrudPageInterface> = () => {
  const { dispatchFeedback } = useContext(FeedbackContext);
  const { id } = useParams();
  const [property, setProperty] = useState<PropertyInterface | undefined>(
    undefined
  );

  useEffect(() => {
    getPropertyById(id, undefined)
      .then(({ data }) => setProperty(data))
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      );
  }, [dispatchFeedback, id]);

  return (
    <>
      <Grid container spacing={0} style={{ height: "100%" }}>
        <CustomTableGrid item xs={12} md={6} style={{ height: "100%" }}>
          {property ? (
            <PropriedadeForm property={property} />
          ) : (
            <NotFoundFullScreen
              message="Carregando!"
              subtitle="Estamos carregando a propriedade, aguarde!"
            />
          )}
        </CustomTableGrid>
        <Grid item xs={12} md={6} style={{ height: "100%" }}>
          <MapBootstrap />
        </Grid>
      </Grid>
    </>
  );
};

export default PropriedadeCrudPage;
