import { axiosGetCache } from "./../axiosCache";
import { UserInterface } from "./../users/getAllUsers";
import { Token, CustomAxiosResponse, VariantEnum } from "../axiosService";

export interface CityInterface {
  id: number;
  name: string;
  state_id: number;
}

/**
 * @param token
 */
export const getCitiesByIds = async (
  user: UserInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _token: Token
): Promise<CustomAxiosResponse<CityInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/cities/`;

    const response = await axiosGetCache<CityInterface[]>(endpoint);

    const arr = response.data.filter(
      ({ id }) => !!user.cities_allowed.find((cityId) => cityId === id)
    );

    return {
      ...response,
      data: arr,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
