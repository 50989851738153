import { createMuiTheme, Theme } from "@material-ui/core/styles";

const FONT_FAMILY = '"Roboto", "Helvetica", "Arial", sans-serif';

export const planaltoTheme = (prefersDarkMode: boolean): Theme => {
  const theme = createMuiTheme({
    palette: {
      type: prefersDarkMode ? "dark" : "light",
      // type: "light",
      // type: "dark",
      primary: {
        main: "#3F4C67",
        contrastText: "#FFF",
        dark: "#15233E",
        light: "#3F4C67",
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: FONT_FAMILY,
      fontSize: 12,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
        fontSize: "4.5rem", // 6em 25% reduction
        lineHeight: 1.167,
        letterSpacing: "-0.01562em",
      },
      h2: {
        fontFamily: FONT_FAMILY,
        fontWeight: 300,
        fontSize: "2.8125rem",
        lineHeight: 1.2,
        letterSpacing: "-0.00833em",
      },
      h3: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "2.25rem",
        lineHeight: 1.167,
        letterSpacing: "0em",
      },
      h4: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "1.59375rem",
        lineHeight: 1.235,
        letterSpacing: "0.00735em",
      },
      h5: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "1.125rem",
        lineHeight: 1.334,
        letterSpacing: "0em",
      },
      h6: {
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
        fontSize: "0.9375rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
      },
      subtitle1: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
      },
      subtitle2: {
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
        fontSize: "0.65625rem",
        lineHeight: 1.57,
        letterSpacing: "0.00714em",
      },
      body1: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
      },
      body2: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "0.65625rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
      },
      button: {
        fontFamily: FONT_FAMILY,
        fontWeight: 500,
        fontSize: "0.65625rem",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
      },
      caption: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "0.5625rem",
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
      },
      overline: {
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        fontSize: "0.5625rem",
        lineHeight: 2.66,
        letterSpacing: "0.08333em",
        textTransform: "uppercase",
      },
    },
    overrides: {
      MuiTextField: {
        root: {
          marginBottom: "2.0em",
        },
      },
      MuiTableCell: {
        root: {
          fontSize: "0.675rem",
        },
      },
    },
  });

  return theme;
};
