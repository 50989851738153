import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import NotFoundFullScreen from "../../components/NotFoundFullScreen/NotFoundFullScreen";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../services/axiosService";
import {
  CompanyInterface,
  getAllCompany,
} from "../../services/company/getAllCompany";
import SelectEmpresa from "./sections/SelectEmpresa";

interface EmpresaPageInterface {}

const EmpresaPage: React.FC<EmpresaPageInterface> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const { dispatchFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    setIsLoading(true);
    getAllCompany(undefined)
      .then(({ data }) => setCompanies(data))
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      )
      .finally(() => setIsLoading(false));
  }, [dispatchFeedback]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          {!isLoading ? (
            <SelectEmpresa companies={companies} />
          ) : (
            <NotFoundFullScreen
              message="Carregando!"
              subtitle="Estamos carregando as empresas, aguarde!"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EmpresaPage;
