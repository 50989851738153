export enum IndexedDBStoreEnum {
  coordinates = "coordinates",
}

export const DBConfig = {
  name: "antarctica-db",
  version: 1,
  objectStoresMeta: [
    {
      store: IndexedDBStoreEnum.coordinates,
      storeConfig: { keyPath: "getPropsFromQueryString", autoIncrement: false },
      storeSchema: [
        {
          name: "properties",
          keypath: "properties",
          options: { unique: true },
        },
      ],
    },
  ],
};
