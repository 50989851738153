import React from "react";
import { Outlet } from "react-router";
import LayoutProvider from "./contexts/LayoutContext/LayoutContext";
import PageLayoutProvider from "./providers/PageLayoutProvider";

interface RootPageInterface {}

const RootPage: React.FC<RootPageInterface> = () => {
  return (
    <>
      <LayoutProvider>
        <PageLayoutProvider>
          <Outlet />
        </PageLayoutProvider>
      </LayoutProvider>
    </>
  );
};

export default RootPage;
