import { Box } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { MenuConfig } from "../../configs/MenuConfig";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../contexts/LayoutContext/LayoutInterface";
import Middleware from "../../middlewares/Middleware";
import { PlanaltoRouterPathsEnum } from "../../routes/CRoute";

interface CaixaRootPageInterface {}

const CaixaRootPage: React.FC<CaixaRootPageInterface> = () => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: "Propriedades",
      },
    });
  }, [dispatchLayout]);

  const rolesAllowed = MenuConfig[0].find(
    ({ slug }) => slug === PlanaltoRouterPathsEnum.empresas
  )?.rolesAllowed;

  return (
    <>
      <Middleware rolesAllowed={rolesAllowed}>
        <Box m={3} style={{ height: "100%" }}>
          <Outlet />
        </Box>
      </Middleware>
    </>
  );
};

export default CaixaRootPage;
