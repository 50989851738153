import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import DialogForm from "../../../components/DialogForm";
import { FeedbackContext } from "../../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../../services/axiosService";
import { deleteCompany } from "../../../services/company/deleteCompany";
import { CompanyInterface } from "../../../services/company/getAllCompany";

interface CompanyCardInterface {
  company: CompanyInterface;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const CompanyCard: React.FC<CompanyCardInterface> = ({ company }) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isDeletingCompany, setIsDeletingCompany] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { handleSubmit } = useForm<CompanyInterface>();
  const { dispatchFeedback } = useContext(FeedbackContext);

  const companySubmitMethod = async () => {
    setIsDeletingCompany(true);

    deleteCompany(company.id, undefined)
      .then(({ message, variant }) => {
        setIsHidden(true);
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant,
        });
      })
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      )
      .finally(() => {
        setIsDeletingCompany(false);
        setOpenDeleteDialog(false);
      });
  };

  return !isHidden ? (
    <>
      <DialogForm
        open={openDeleteDialog}
        onDisagree={() => setOpenDeleteDialog(false)}
        contentTitle={`Deletar empresa: ${company.name}`}
        maxWidth="xs"
        handleSubmit={handleSubmit}
        submitMethod={companySubmitMethod}
        loading={isDeletingCompany}
      >
        <Typography gutterBottom>
          CNPJ: {company.cnpj}
          <br />
          <br />
          Essa operação não pode ser desfeita!
        </Typography>
      </DialogForm>

      <Card elevation={0} variant="outlined">
        <CardContent>
          <Typography color="textSecondary" variant="caption" gutterBottom>
            Desde{" "}
            {new Date(company.createdAt).toLocaleDateString(
              "pt-BR",
              dateOptions
            )}
          </Typography>
          <Typography variant="h5" component="h2">
            {company.name}
          </Typography>
          <Typography color="textSecondary">{company.cnpj}</Typography>
        </CardContent>
        <CardActions>
          <Button disabled size="small">
            Editar
          </Button>
          <Button onClick={() => setOpenDeleteDialog(true)} size="small">
            Deletar
          </Button>
        </CardActions>
      </Card>
    </>
  ) : null;
};

export default CompanyCard;
