import React, { useContext } from "react";
import { isMobile, MobileView } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Box,
  Button,
} from "@material-ui/core";
import { BottomNavigationMobile } from "../components/BottomNavigation";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import { MenuConfig, MenuItemInterface } from "../configs/MenuConfig";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Middleware from "../middlewares/Middleware";
import { AuthContext } from "../contexts/AuthContext/AuthContext";
import { AuthTypesEnum } from "../contexts/AuthContext/AuthInterface";
import { ExitToAppOutlined } from "@material-ui/icons";
import ApplicationLoading from "../sections/ApplicationLoading";

const drawerWidth = 240;
const appbarHeight = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    height: `${appbarHeight}px`,
    width: !isMobile ? `calc(100% - ${drawerWidth}px)` : "100%",
    marginLeft: !isMobile ? drawerWidth : 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    height: `calc(100vh - ${appbarHeight}px)`,
    // padding: theme.spacing(3),
  },
}));

interface StyledTypography {}
const CustomTitle = styled(Typography)<StyledTypography>`
  flex-grow: 1;
`;

const PageLayoutProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { dispatchAuth } = useContext(AuthContext);
  const { stateLayout } = useContext(LayoutContext);
  const classes = useStyles();

  const handleClickItem = (menu: MenuItemInterface): void => {
    if (menu.disabled) return;
    if (menu.onClick) menu.onClick();
    if (menu.redirectUrl) navigate(menu.redirectUrl);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <CustomTitle variant="h6">{stateLayout.title}</CustomTitle>
            {MenuConfig.map((section) => (
              <>
                {section.map((menu, index) => (
                  <Middleware
                    key={`menu-${index}`}
                    rolesAllowed={menu.rolesAllowed}
                  >
                    <Box
                      display="flex"
                      ml={3}
                      flexDirection="row"
                      alignSelf="center"
                    >
                      <Button
                        key={menu.slug}
                        onClick={() => handleClickItem(menu)}
                        color="inherit"
                      >
                        <Box display="flex" alignSelf="center" pr={1}>
                          {menu.icon}
                        </Box>
                        <Box display="flex" alignSelf="center">
                          {menu.label}
                        </Box>
                      </Button>
                    </Box>
                  </Middleware>
                ))}
              </>
            ))}
            <Divider orientation="vertical" />
            <Box display="flex" ml={3} flexDirection="row" alignSelf="center">
              <Button
                onClick={() =>
                  dispatchAuth({ type: AuthTypesEnum.LOGOUT_USER })
                }
                color="inherit"
              >
                <Box display="flex" alignSelf="center" pr={1}>
                  <ExitToAppOutlined />
                </Box>
                <Box display="flex" alignSelf="center">
                  Sair
                </Box>
              </Button>
            </Box>
          </Toolbar>
          <ApplicationLoading />
        </AppBar>
        {/* <BrowserView>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Toolbar>
              <Typography variant="h6" noWrap>
                Planalto
              </Typography>
            </Toolbar>
            <Divider />
            {MenuConfig.map((section, index) => (
              <>
                <List key={`section-${index}`}>
                  {section.map((menu) => (
                    <ListItem
                      // disabled={Object.values(menuItems).find(obj => )}
                      onClick={() => handleClickItem(menu)}
                      button
                      key={menu.slug}
                    >
                      <ListItemIcon>{menu.icon}</ListItemIcon>
                      <ListItemText primary={menu.label} />
                    </ListItem>
                  ))}
                </List>
                {MenuConfig.length - 1 !== index && <Divider />}
              </>
            ))}
          </Drawer>
        </BrowserView> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
      <MobileView>
        <Box pt={1} minHeight="calc(100vh - 60px)">
          {children}
          <BottomNavigationMobile />
        </Box>
      </MobileView>
    </>
  );
};

export default PageLayoutProvider;
