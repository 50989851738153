import { Grow, Snackbar, SnackbarCloseReason } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { FeedbackContext } from "../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../contexts/FeedbackContext/FeedbackInterface";

interface FeedbackSectionInterface {}

const FeedbackSection: React.FC<FeedbackSectionInterface> = () => {
  const { stateFeedback, dispatchFeedback } = useContext(FeedbackContext);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleSnackbarClose = (
    _: React.SyntheticEvent,
    reason: SnackbarCloseReason
  ): void => {
    if (reason === "clickaway") return;

    setOpenSnackbar(false);
    dispatchFeedback({ type: FeedbackTypesEnum.HIDE_SNACKBAR });
  };

  // eslint-disable-next-line
  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    handleSnackbarClose(event, reason);
  };

  useEffect(() => {
    if (stateFeedback.snackbar?.timestamp) {
      setOpenSnackbar(true);
    }
  }, [stateFeedback.snackbar?.timestamp]);

  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
      color={stateFeedback.snackbar?.variant}
      TransitionComponent={Grow}
      message={stateFeedback.snackbar?.message}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      key={"general-snackbar"}
    >
      {/* eslint-disable-next-line */}
      <Alert severity={stateFeedback.snackbar?.variant || "info"}>
        {stateFeedback.snackbar?.message}
      </Alert>
    </Snackbar>
  );
};

export default FeedbackSection;
