/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps,
} from "mui-datatables";
import { MUI_DATABLE_DEFAULT_OPTIONS } from "./MUITSDatatableConfig";

interface MUITSDatatableInterface<DATA> extends MUIDataTableProps {
  options: Partial<MUIDataTableOptions>;
}

const MUITSDatatable: <DATA>(
  props: MUITSDatatableInterface<DATA>
) => React.ReactElement = ({ title, data, columns, options, components }) => {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{
        ...MUI_DATABLE_DEFAULT_OPTIONS,
        ...options,
      }}
      components={components}
    />
  );
};

export default MUITSDatatable;
