import { UserInterface } from "./getAllUsers";
import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "./../axiosService";

export const getUserByFirebaseId = async (
  token: Token,
  UUID: string
): Promise<CustomAxiosResponse<UserInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/user/getUserByFirebaseId/${UUID}`;

    const response = await axiosGET<UserInterface>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, ao carregar o usuário."
    );
  }
};
