import { LayoutContextInterface } from "./../LayoutInterface";
import { CONFIGURE_PAGE } from "../LayoutInterface";

export function configurePageAction(
  state: LayoutContextInterface,
  action: CONFIGURE_PAGE
): LayoutContextInterface {
  return {
    ...state,
    ...action.page,
  };
}
