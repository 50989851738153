import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export type Endpoint = string;
export type Body = Record<string, unknown>;
export type Token = string | undefined;
export type Otherheaders = AxiosRequestConfig;

export enum VariantEnum {
  default = "info",
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export interface CustomAxiosResponse<T> extends AxiosResponse<T> {
  message: string;
  variant: VariantEnum;
}

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPUT = async <T>(
  endpoint: Endpoint,
  body: Body,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.put(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed: boolean = response.status >= 200 && response.status < 300;
    const variant: VariantEnum = succeed
      ? VariantEnum.success
      : VariantEnum.error;

    return {
      ...response,
      message: "Sucesso!",
      variant,
    };
  } catch (error) {
    return error;
  }
};

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPATCH = async <T>(
  endpoint: Endpoint,
  body: Body,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.patch(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed: boolean = response.status >= 200 && response.status < 300;
    const variant: VariantEnum = succeed
      ? VariantEnum.success
      : VariantEnum.error;

    return {
      ...response,
      message: "Sucesso!",
      variant,
    };
  } catch (error) {
    return error;
  }
};

/**
 * Use to get
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosGET = async <T>(
  endpoint: Endpoint,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.success
      : VariantEnum.error;

    return {
      ...response,
      message: "Sucesso!",
      variant,
    };
  } catch (error) {
    throw new Error("Ooops, aconteceu um problema, tente novamente!");
  }
};

/**
 * Use to post
 *
 * @deprecated
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosDeprecatedPOST = async <T>(
  endpoint: Endpoint,
  body: Body,
  token?: Token,
  otherHeaders?: Otherheaders
): Promise<AxiosResponse<T>> => {
  try {
    const payloadToken = token ? { Authorization: `Bearer ${token}` } : {};
    const paylaodOtherheaders = otherHeaders || {};

    const response = await axios.post(endpoint, body, {
      headers: {
        ...payloadToken,
        ...paylaodOtherheaders,
      },
    });

    return response;
  } catch (error) {
    if (error.response) {
      console.error(error.response);
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message;
    }
  }
};

export const axiosPOST = async <T>(
  endpoint: Endpoint,
  body: Body,
  token: Token,
  otherHeaders: Otherheaders = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.post(endpoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.success
      : VariantEnum.error;

    return {
      ...response,
      message: "Sucesso!",
      variant,
    };
  } catch (error) {
    return error;
  }
};

/**
 * Use to delete
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 * @param {object} otherData
 */
export const axiosDelete = async <T>(
  endpoint: Endpoint,
  token: Token,
  otherHeaders: Otherheaders = {},
  otherData = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const response = await axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
      data: otherData,
    });

    const variant: VariantEnum = checkIfRequestWasSuccessful(response.status)
      ? VariantEnum.success
      : VariantEnum.error;

    return {
      ...response,
      message: "Sucesso!",
      variant,
    };
  } catch (error) {
    return error;
  }
};

export const checkIfRequestWasSuccessful = (status: number): boolean =>
  status >= 200 && status < 300;
