import {
  CoordinatesType,
  getPropsFromQueryStringInterface,
} from "./../../services/properties/getPropsFromQueryString";
import { PropertyInterface } from "./../../services/properties/getProperties";

export type MapType = google.maps.Map | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MapsType = any | undefined;

interface MapPropertyInterface extends PropertyInterface {
  color: string;
}

export interface MapOptionsInterface {
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillOpacity: number;
  useDisplayedOnly: boolean;
}

export interface PolygonsOnMap {
  [propertyId: string]: {
    polygons: google.maps.Polygon[];
    infoWindow: google.maps.InfoWindow;
  };
}

export interface MapsStateContextInterface {
  map: MapType | undefined;
  maps: MapsType | undefined;
  fetchingProperties: string[];
  propertiesOnMap: MapPropertyInterface[];
  propertiesWithCoordinatesOnMap: getPropsFromQueryStringInterface[];
  properties: PropertyInterface[];
  lastPropertyAdded: PropertyInterface | undefined;
  mapOptions: MapOptionsInterface;
  polygons: PolygonsOnMap;
}

export enum MapsStateTypesEnum {
  UPDATE_STATE,
  ADD_MAPS_TO_STATE,
  ADD_PROPERTY_TO_MAP,
  ADD_PROPERTIES_WITH_COORDINATES_ON_MAP,
  REMOVE_PROPERTY_FROM_MAP,
  ADD_PROPERTIES,
  ADD_POLYGON_TO_MAP,
  REMOVE_ALL_POLYGONS_FROM_MAP,
  CHANGE_MAP_OPTIONS,
  UPDATE_CUSTOM_FIELDS,
}

export type UPDATE_STATE = {
  type: MapsStateTypesEnum.UPDATE_STATE;
  newState: MapsStateContextInterface;
};

export type ADD_MAPS_TO_STATE = {
  type: MapsStateTypesEnum.ADD_MAPS_TO_STATE;
  map: MapType;
  maps: MapsType;
};

export type ADD_PROPERTY_TO_MAP = {
  type: MapsStateTypesEnum.ADD_PROPERTY_TO_MAP;
  property: PropertyInterface;
};

export type ADD_PROPERTIES_WITH_COORDINATES_ON_MAP = {
  type: MapsStateTypesEnum.ADD_PROPERTIES_WITH_COORDINATES_ON_MAP;
  properties: getPropsFromQueryStringInterface[];
};

export type REMOVE_PROPERTY_FROM_MAP = {
  type: MapsStateTypesEnum.REMOVE_PROPERTY_FROM_MAP;
  property: PropertyInterface;
};

export type ADD_PROPERTIES = {
  type: MapsStateTypesEnum.ADD_PROPERTIES;
  properties: PropertyInterface[];
};

export type ADD_POLYGON_TO_MAP = {
  type: MapsStateTypesEnum.ADD_POLYGON_TO_MAP;
  coordinates: CoordinatesType;
  property: PropertyInterface | MapPropertyInterface;
  changeViewPort?: boolean;
  boundsZoom?: number;
};

export type CHANGE_MAP_OPTIONS = {
  type: MapsStateTypesEnum.CHANGE_MAP_OPTIONS;
  options: Partial<MapOptionsInterface>;
};

export type REMOVE_ALL_POLYGONS_FROM_MAP = {
  type: MapsStateTypesEnum.REMOVE_ALL_POLYGONS_FROM_MAP;
};
