import React from "react";
import { Outlet } from "react-router";

interface LoginRootPageInterface {}

const LoginRootPage: React.FC<LoginRootPageInterface> = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default LoginRootPage;
