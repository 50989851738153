import React from "react";
import "./App.css";
import AuthProvider from "./contexts/AuthContext/AuthContext";
import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/app";
import ThemeProvider from "./providers/ThemeProvider";
import Router from "./routes/Router";
import FirebaseRemoteConfigProvider from "./contexts/Firebase/RemoteConfig/FirebaseRemoteConfigProvider";
import FeedbackProvider from "./contexts/FeedbackContext/FeedbackContext";
import FeedbackSection from "./sections/FeedbackSection";
import FirestoreProvider from "./contexts/FirestoreContext/FirestoreContext";
import { initDB } from "react-indexed-db";
import { DBConfig } from "./configs/DBConfig";

firebase.initializeApp(firebaseConfig);
initDB(DBConfig);

const remoteConfig = firebase.remoteConfig();
const firestore = firebase.firestore();

function App(): JSX.Element {
  return (
    <>
      <FirebaseRemoteConfigProvider remoteConfig={remoteConfig}>
        <FirestoreProvider firestore={firestore}>
          <ThemeProvider>
            <FeedbackProvider>
              <AuthProvider>
                <Router />
                <FeedbackSection />
              </AuthProvider>
            </FeedbackProvider>
          </ThemeProvider>
        </FirestoreProvider>
      </FirebaseRemoteConfigProvider>
    </>
  );
}

export default App;
