import { CompanyInterface } from "./getAllCompany";
import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosPOST,
} from "./../axiosService";

type CreateCompanyInterface = Partial<
  Omit<CompanyInterface, "id" | "createdAt">
>;

export const createCompany = async (
  body: CompanyInterface,
  token: Token
): Promise<CustomAxiosResponse<CompanyInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/company`;

    const userToBeSaved: CreateCompanyInterface = body;

    const response = await axiosPOST<CompanyInterface>(
      endpoint,
      userToBeSaved,
      token
    );

    return {
      ...response,
      data: response.data,
      message: `Empresa "${body.name}" criada!`,
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message
        ? error.message
        : "Ocorreu um erro, ao criar a empresa, tente novamente."
    );
  }
};
