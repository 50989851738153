import React from "react";
import { PropertyInterface } from "./../services/properties/getProperties";
import ReactDOMServer from "react-dom/server";
import {
  MapsStateContextInterface,
  MapType,
} from "./../contexts/MapsStateContext/MapsStateInterface";
import { Coordinate, CoordinatesType } from "./../services/properties/getPropsFromQueryString";
import RenderTableCropsAreas from "../pages/propriedades/helpers/RenderTableCropsAreas";
import { Box, Divider, Typography } from "@material-ui/core";

export interface AddPolygonsToMapResponseInterface {
  polygons: google.maps.Polygon[];
  infoWindow: google.maps.InfoWindow;
}

export function addPolygonsToMap(
  coordinates: CoordinatesType,
  stateMapsState: MapsStateContextInterface,
  map: MapType,
  color: string,
  property: PropertyInterface,
  changeViewToProperty = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _boundsZoom = 100
): AddPolygonsToMapResponseInterface {
  const polygons: google.maps.Polygon[] = [];
  let infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

  // check if array has an array
  const _isAnArray = Array.isArray(coordinates[0]);
  const _coordinates = (!_isAnArray ? [coordinates] : coordinates) as CoordinatesType[];
  // const _coordinates = _coordinates2.flatMap((e) => e);
  
  // check if is array of array
  _coordinates.forEach((singlePolygon) => {
    // (100 - 1 * stateMapsState.mapOptions.fillOpacity) / 100,
    const polygon = new google.maps.Polygon({
      paths: singlePolygon,
      strokeColor: stateMapsState.mapOptions.strokeColor,
      strokeWeight: stateMapsState.mapOptions.strokeWeight,
      strokeOpacity: 1,
      fillOpacity: 0.35,
      fillColor: color,
    });

    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < _coordinates[0].length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { lat, lng } = _coordinates[0][i];
      bounds.extend(new google.maps.LatLng(lat, lng));
    }

    if (!map) return;

    changeViewToProperty &&
      map.fitBounds(bounds, {
        bottom: 100,
        left: 100,
        right: 100,
        top: 100,
      });

    // infoWindow.open(map);
    // setTimeout(() => infoWindow.close(), 4000);
    const content = ReactDOMServer.renderToStaticMarkup(
      <>
        <Typography gutterBottom variant="body2" align="center">
          {property.propriedade}
        </Typography>
        <Box mt={1}>
          <Divider />
        </Box>
        <RenderTableCropsAreas property={property} />
      </>
    );
    infoWindow = new google.maps.InfoWindow({
      position: {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      },
      content,
    });

    polygon.addListener("click", () => {
      infoWindow.open(map);
    });

    polygon.setMap(map);
    polygons.push(polygon);
  });

  return {
    polygons,
    infoWindow,
  };
}
