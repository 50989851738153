/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "firebase/remote-config";
import {
  FirebaseRemoteConfigProviderInterface,
  CustomFieldsInterface,
  RemoteConfigInterface,
  RemoteConfigKeys,
} from "./FirebaseRemoteConfigInterfaces";
import { remoteConfigInitialState } from "./FirebaseRemoteConfigInit";

export const FirebaseRemoteConfigContext = React.createContext<RemoteConfigInterface>(
  remoteConfigInitialState
);

const FirebaseRemoteConfigProvider: React.FC<FirebaseRemoteConfigProviderInterface> = ({
  children,
  remoteConfig,
}) => {
  const [customFields, setCustomFields] = useState<CustomFieldsInterface>(
    remoteConfigInitialState.customFields
  );

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 10000,
    fetchTimeoutMillis: 60000,
  };

  remoteConfig.defaultConfig = {};

  useEffect(() => {
    function setValues(): void {
      setCustomFields({
        [RemoteConfigKeys.agricultura]: remoteConfig.getBoolean(
          RemoteConfigKeys.agricultura
        ),
        [RemoteConfigKeys.pastagem]: remoteConfig.getBoolean(
          RemoteConfigKeys.pastagem
        ),
        [RemoteConfigKeys.floresta]: remoteConfig.getBoolean(
          RemoteConfigKeys.floresta
        ),
        [RemoteConfigKeys.rank]: remoteConfig.getBoolean(RemoteConfigKeys.rank),
        [RemoteConfigKeys.segmentacao]: remoteConfig.getBoolean(
          RemoteConfigKeys.segmentacao
        ),
        [RemoteConfigKeys.potencial]: remoteConfig.getBoolean(
          RemoteConfigKeys.potencial
        ),
        [RemoteConfigKeys.pot_explorado]: remoteConfig.getBoolean(
          RemoteConfigKeys.pot_explorado
        ),
        [RemoteConfigKeys.porcentagem]: remoteConfig.getBoolean(
          RemoteConfigKeys.porcentagem
        ),
        [RemoteConfigKeys.pot_nao_explorado]: remoteConfig.getBoolean(
          RemoteConfigKeys.pot_nao_explorado
        ),
        [RemoteConfigKeys.propriedade]: remoteConfig.getBoolean(
          RemoteConfigKeys.propriedade
        ),
        [RemoteConfigKeys.proprietario]: remoteConfig.getBoolean(
          RemoteConfigKeys.proprietario
        ),
        [RemoteConfigKeys.operador]: remoteConfig.getBoolean(
          RemoteConfigKeys.operador
        ),
        [RemoteConfigKeys.nomeContato]: remoteConfig.getBoolean(
          RemoteConfigKeys.nomeContato
        ),
        [RemoteConfigKeys.telefoneContato]: remoteConfig.getBoolean(
          RemoteConfigKeys.telefoneContato
        ),
      });
    }

    function fetchConfig(): void {
      remoteConfig
        .fetchAndActivate()
        .then(() => setValues())
        .catch((err) => console.error(err));
    }

    remoteConfig
      .ensureInitialized()
      .then(() => fetchConfig())
      .catch((err) =>
        console.error("Firebase Remote Config failed to initialize", err)
      );
  }, [remoteConfig]);

  const globals = {
    customFields,
  };

  return (
    <FirebaseRemoteConfigContext.Provider value={globals}>
      {children}
    </FirebaseRemoteConfigContext.Provider>
  );
};

export default FirebaseRemoteConfigProvider;
