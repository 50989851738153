import firebase from "firebase/app";
import { PlanaltoRouterPathsEnum } from "../../../routes/CRoute";

export interface FRCMenuItemInterface {
  slug: PlanaltoRouterPathsEnum;
  disabled: boolean;
}

export type CustomFieldBoolean = boolean;

export type CustomFieldsInterface = {
  [key in RemoteConfigKeys]: CustomFieldBoolean;
};

export interface RemoteConfigInterface {
  customFields: CustomFieldsInterface;
}

export interface FirebaseRemoteConfigProviderInterface {
  remoteConfig: firebase.remoteConfig.RemoteConfig;
}

export enum RemoteConfigKeys {
  agricultura = "agricultura_editavel",
  pastagem = "pastagem_editavel",
  floresta = "floresta_editavel",
  rank = "rank_editavel",
  segmentacao = "segmentacao_editavel",
  potencial = "potencial_editavel",
  pot_explorado = "pot_explorado_editavel",
  porcentagem = "porcentagem_editavel",
  pot_nao_explorado = "pot_nao_explorado_editavel",
  propriedade = "propriedade_editavel",
  proprietario = "proprietario_editavel",
  operador = "operador_editavel",
  nomeContato = "nome_contato_editavel",
  telefoneContato = "telefone_contato_editavel",
}
