import { LinearProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";

interface ApplicationLoadingInterface {}

const ApplicationLoading: React.FC<ApplicationLoadingInterface> = () => {
  const { stateLayout } = useContext(LayoutContext);

  const value = stateLayout.value || 0;

  return stateLayout.isLoading && value < 100 ? (
    <LinearProgress
      color="secondary"
      variant={stateLayout.loadingType}
      value={value}
    />
  ) : null;
};

export default ApplicationLoading;
