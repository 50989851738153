import { Grid } from "@material-ui/core";
import React from "react";
import { CompanyInterface } from "../../../services/company/getAllCompany";
import CreateEmpresaCard from "../components/CreateEmpresaCard";
import EmpresaCard from "../components/EmpresaCard";

interface SelectEmpresaInterface {
  companies: CompanyInterface[];
}

const SelectEmpresa: React.FC<SelectEmpresaInterface> = ({ companies }) => {
  return (
    <Grid container spacing={2} alignItems="stretch">
      {companies.map((company, index) => (
        <Grid key={index} item xs={12} md={3}>
          <EmpresaCard company={company} />
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        md={3}
        style={{ display: "flex", flexGrow: 1, width: "100%" }}
      >
        <CreateEmpresaCard />
      </Grid>
    </Grid>
  );
};

export default SelectEmpresa;
