import {
  REMOVE_ALL_POLYGONS_FROM_MAP,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function removeAllPolygonsFromMapAction(
  state: MapsStateContextInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: REMOVE_ALL_POLYGONS_FROM_MAP
): MapsStateContextInterface {
  const polygons = Object.values(state.polygons);

  const asyncrequest = async () => {
    polygons.forEach(({ polygons, infoWindow }) => {
      polygons.forEach((polygon) => polygon.setMap(null));
      infoWindow.close();
    });
  };

  asyncrequest();

  return state;
}
