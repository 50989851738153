import {
  ADD_PROPERTIES_WITH_COORDINATES_ON_MAP,
  MapsStateContextInterface,
  MapsStateTypesEnum,
} from "../MapsStateInterface";
import { removeAllPolygonsFromMapAction } from "./removeAllPolygonsFromMapAction";

export function addPropertiesWithCoordinatesToMapAction(
  state: MapsStateContextInterface,
  action: ADD_PROPERTIES_WITH_COORDINATES_ON_MAP
): MapsStateContextInterface {
  removeAllPolygonsFromMapAction(state, {
    type: MapsStateTypesEnum.REMOVE_ALL_POLYGONS_FROM_MAP,
  });

  return {
    ...state,
    propertiesWithCoordinatesOnMap: action.properties,
  };
}
