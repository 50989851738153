import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { CityInterface } from "../../../services/cities/getAllCities";
import { Checkbox } from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";
import TextField from "../../../components/TextField";
import { CitiesAllowedType } from "../../../services/users/getAllUsers";

interface CitiesAutocompleteInterface {
  props: {
    onChange: (...event: CityInterface[]) => void;
    onBlur: () => void;
    value: unknown;
    name: string;
    ref: React.MutableRefObject<unknown>;
  };
  cities: CityInterface[];
  userCitiesAllowed: CitiesAllowedType;
  selectedCities: CityInterface[];
  setSelectedCities: React.Dispatch<React.SetStateAction<CityInterface[]>>;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxOutlined fontSize="small" />;

const CitiesAutocomplete: React.FC<CitiesAutocompleteInterface> = ({
  userCitiesAllowed,
  props,
  cities,
  selectedCities,
  setSelectedCities,
}) => {
  const handleOnChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any,
    newValue: CityInterface[]
  ) => {
    setSelectedCities(newValue);
    props.onChange(event);
  };

  useEffect(() => {
    // Avoid infinite loop
    if (userCitiesAllowed.length === 0) return;

    const formatted = userCitiesAllowed
      .map((id) => cities.find((city) => city.id === id))
      .filter((element) => element !== undefined) as CityInterface[];

    setSelectedCities(formatted);
  }, [setSelectedCities, cities, userCitiesAllowed]);

  return (
    <>
      <Autocomplete
        {...props}
        multiple
        id="checkboxes-tags-demo"
        options={cities}
        onChange={handleOnChange}
        value={selectedCities}
        disableCloseOnSelect
        getOptionLabel={({ name }) => name}
        renderOption={(option, { selected }) => {
          return (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            autoComplete="off"
            label="Municípios que o usuário pode visualizar"
            placeholder="Clique para selecionar"
          />
        )}
      />
    </>
  );
};

export default React.memo(CitiesAutocomplete);
