import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "../axiosService";

export type CoordinatesType = Array<Coordinate[]>;

export interface getPropCoordByIdInterface {
  coordinates: CoordinatesType;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export const getPropCoordById = async (
  token: Token,
  uuid: string
): Promise<CustomAxiosResponse<getPropCoordByIdInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/properties/coord/${uuid}`;

    const response = await axiosGET<getPropCoordByIdInterface>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
