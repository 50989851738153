import { FirebaseAuth } from "./../../contexts/AuthContext/AuthInterface";
import { UserInterface } from "./getAllUsers";
import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosPOST,
} from "./../axiosService";

type CreateUserInterface = Partial<
  Omit<UserInterface, "id" | "createdAt" | "updatedAt">
>;

export const createUser = async (
  body: UserInterface,
  firebaseAuth: FirebaseAuth,
  token: Token
): Promise<CustomAxiosResponse<void>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/user`;

    const firebaseUser = await firebaseAuth?.createUserWithEmailAndPassword(
      body.email,
      body.password
    );

    const uid = firebaseUser?.user?.uid;

    if (!uid)
      throw new Error(
        "Não foi possível criar o usuário no firebase, talvez ele já exista."
      );

    const userToBeSaved: CreateUserInterface = {
      ...body,
      firebase_id: uid,
    };

    const response = await axiosPOST<void>(endpoint, userToBeSaved, token);

    return {
      ...response,
      data: undefined,
      message: `Usuário "${body.name}" criado!`,
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, ao criar o usuário."
    );
  }
};
