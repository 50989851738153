import {
  FirestoreAction,
  FirestoreContextInterface,
  FirestoreTypesEnum,
} from "./FirestoreInterface";
import { updateStateAction } from "./actions/updateStateAction";

function updateStateReducer(
  state: FirestoreContextInterface,
  action: FirestoreAction
): FirestoreContextInterface {
  switch (action.type) {
    case FirestoreTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    default:
      return state;
  }
}

export default updateStateReducer;
