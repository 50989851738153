import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";

interface PaperSectionInterface {
  first?: boolean;
  title?: string;
  subtitle?: string;
}

const PaperSection: React.FC<PaperSectionInterface> = ({
  children,
  title,
  first,
  subtitle,
}) => {
  return (
    <Paper>
      <Box p={2} mb={2} mt={first ? 0 : 1}>
        {title && (
          <Box mb={2}>
            <Typography gutterBottom variant="h6">
              {title}
            </Typography>
          </Box>
        )}
        {subtitle && (
          <Box mb={2}>
            <Typography gutterBottom variant="body2">
              {subtitle}
            </Typography>
          </Box>
        )}
        {children}
      </Box>
    </Paper>
  );
};

export default PaperSection;
