import { PropertyInterface } from "./getProperties";
import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosPATCH,
} from "./../axiosService";

type UpdatePropertyInterface = Partial<
  Omit<PropertyInterface, "id" | "createdAt" | "updatedAt">
>;

export const updateProperty = async (
  property: PropertyInterface,
  token: Token
): Promise<CustomAxiosResponse<void>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/property/${property.id_propriedades}`;

    const propertyToBeSaved: UpdatePropertyInterface = property;

    const response = await axiosPATCH<void>(endpoint, propertyToBeSaved, token);

    return {
      ...response,
      data: undefined,
      message: "Propriedade atualizada!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message
        ? error.message
        : "Ocorreu um erro, ao atualizar a propriedade."
    );
  }
};
