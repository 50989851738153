import {
  FirestoreContextInterface,
  FirestoreTypesEnum,
} from "../FirestoreInterface";

export type UPDATE_STATE = {
  type: FirestoreTypesEnum.UPDATE_STATE;
  newState: Partial<FirestoreContextInterface>;
};

export function updateStateAction(
  state: FirestoreContextInterface,
  action: UPDATE_STATE
): FirestoreContextInterface {
  return {
    ...state,
    ...action.newState,
  };
}
