import {
  ADD_PROPERTIES,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function addPropertiesAction(
  state: MapsStateContextInterface,
  action: ADD_PROPERTIES
): MapsStateContextInterface {
  return {
    ...state,
    properties: [...state.properties, ...action.properties],
    lastPropertyAdded: action.properties[0],
  };
}
