import React, { useContext } from "react";
import { Outlet, Route } from "react-router";
import { AuthContext } from "../contexts/AuthContext/AuthContext";

interface PrivateRouterInterface {
  RedirectPage: React.ReactElement;
}

const PrivateRouter: React.FC<PrivateRouterInterface> = ({ RedirectPage }) => {
  const { stateAuth } = useContext(AuthContext);

  const isNotLogged =
    stateAuth.firebaseUser === null || stateAuth.loggedUser === undefined;

  return <Route path="/" element={isNotLogged ? RedirectPage : <Outlet />} />;
};

export default PrivateRouter;
