import {
  RemoteConfigInterface,
  RemoteConfigKeys,
} from "./FirebaseRemoteConfigInterfaces";

export const remoteConfigInitialState: RemoteConfigInterface = {
  customFields: {
    [RemoteConfigKeys.agricultura]: true,
    [RemoteConfigKeys.pastagem]: true,
    [RemoteConfigKeys.floresta]: true,
    [RemoteConfigKeys.rank]: true,
    [RemoteConfigKeys.segmentacao]: true,
    [RemoteConfigKeys.potencial]: true,
    [RemoteConfigKeys.pot_explorado]: true,
    [RemoteConfigKeys.porcentagem]: true,
    [RemoteConfigKeys.pot_nao_explorado]: true,
    [RemoteConfigKeys.propriedade]: true,
    [RemoteConfigKeys.proprietario]: true,
    [RemoteConfigKeys.operador]: true,
    [RemoteConfigKeys.nomeContato]: true,
    [RemoteConfigKeys.telefoneContato]: true,
  },
};
