import {
  ADD_PROPERTY_TO_MAP,
  MapsStateContextInterface,
  MapsStateTypesEnum,
} from "../MapsStateInterface";
import { removePropertyFromMapAction } from "./removePropertyFromMapAction";

export function addPropertyToMapAction(
  state: MapsStateContextInterface,
  action: ADD_PROPERTY_TO_MAP
): MapsStateContextInterface {
  const propertyOnMap = state.propertiesOnMap.find(
    ({ id_propriedades }) => id_propriedades === action.property.id_propriedades
  );

  if (propertyOnMap)
    return removePropertyFromMapAction(state, {
      type: MapsStateTypesEnum.REMOVE_PROPERTY_FROM_MAP,
      property: propertyOnMap,
    });

  return {
    ...state,
    propertiesOnMap: [
      {
        ...action.property,
        color: "rgba(139, 0, 0,1)",
        // color: getRandomColorFromRange(),
      },
      ...state.propertiesOnMap,
    ],
  };
}
