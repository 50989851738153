import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Link,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Palette } from "@material-ui/core/styles/createPalette";
import React from "react";
import styled from "styled-components";
import { PlanaltoRouterPathsEnum } from "../../../routes/CRoute";
import { navigateTo } from "../../../routes/navigateTo";

interface CreateEmpresaCardInterface {}

interface CustomCard {
  palette: Palette;
}

const CustomCard = styled(Card)<CustomCard>`
  border: 1px dashed ${({ palette }) => palette.primary.main};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const CreateEmpresaCard: React.FC<CreateEmpresaCardInterface> = () => {
  const { palette } = useTheme();

  return (
    <CustomCard variant="outlined" palette={palette}>
      <CardContent>
        <Typography color="primary">Quer adicionar outra empresa?</Typography>
        <Typography color="textSecondary">
          Crie uma empresa dentro do menu de{" "}
          <Link href={navigateTo(PlanaltoRouterPathsEnum.usuarios)}>
            usuários
          </Link>
          .
        </Typography>
      </CardContent>
      <CardActions>
        <Button disabled color="primary" size="small">
          Criar nova
        </Button>
      </CardActions>
    </CustomCard>
  );
};

export default CreateEmpresaCard;
