import { PropertyInterface } from "./getProperties";
import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "./../axiosService";

/**
 * @deprecated
 *
 * TODO: This is a workaround, this endpoint get all properties and do a find
 * we need to change this to a single endpoint
 *
 * @param uuid
 * @param token
 */
export const getPropertyById = async (
  uuid: string,
  token: Token
): Promise<CustomAxiosResponse<PropertyInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/properties`;

    const response = await axiosGET<PropertyInterface[]>(endpoint, token);

    const property = response.data.find(
      ({ id_propriedades }) => id_propriedades === uuid
    );

    if (!property) throw new Error("Não foi possível localizar a propriedade.");

    return {
      ...response,
      data: property,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
