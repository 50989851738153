import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

interface HubspotChat {}

const HubspotChat: React.FC<HubspotChat> = () => {
  const [src, setSrc] = useState<string | undefined>(
    process.env.REACT_APP_HUBSPOT_CHAT
  );

  useEffect(() => {
    return (): void => {
      setSrc(undefined);
    };
  }, []);

  return (
    <Helmet>
      <script
        type="text/javascript"
        id="hubspot-chat"
        async
        defer
        src={src}
      ></script>

      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/8615775.js"
      ></script>
    </Helmet>
  );
};

export default HubspotChat;
