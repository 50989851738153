import React from "react";
import { Route } from "react-router-dom";
import { RouteProps } from "react-router";

export enum PlanaltoRouterPathsEnum {
  root = "/",
  propriedade = "propriedade",
  empresas = "empresas",
  propriedadeEdit = ":id",
  propriedades = "propriedades",
  configuracoes = "configuracoes",
  usuarios = "usuarios",
  usuariosEdit = ":firebaseUUID",
  help = "ajuda",
  login = "login",
  logout = "sair",
  signup = "cadastro",
  other = "*",
}

interface CRouteInterface extends Exclude<RouteProps, "path"> {
  path: PlanaltoRouterPathsEnum;
}

const CRoute: React.FC<CRouteInterface> = ({
  children,
  element,
  path,
  ...props
}) => {
  return (
    <Route path={path} element={element} {...props}>
      {children}
    </Route>
  );
};

export default CRoute;
