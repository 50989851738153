import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "../axiosService";

export enum UserRoleEnum {
  "MasterAdmin" = 0,
  "Admin" = 1,
  "RTV" = 2,
  "GerenteComercial" = 3,
  "EstratégiaDeNegócio" = 4,
  "InteligênciaDeMercado" = 5,
}

export type CitiesAllowedType = number[];
export type IdCompanyType = string;

export interface UserInterface {
  id: string;
  firebase_id: string;
  name: string;
  email: string;
  password: string;
  phone: string;
  identity: string;
  occupation: string;
  commercial_structure: string;
  commercial_management: string;
  id_company: IdCompanyType;
  role: UserRoleEnum;
  active: boolean;
  cities_allowed: CitiesAllowedType;
  createdAt: string;
  updatedAt: string;
}

export const getAllUsers = async (
  token: Token
): Promise<CustomAxiosResponse<UserInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/user`;

    const response = await axiosGET<UserInterface[]>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
