import {
  updateCustomFieldsAction,
  UPDATE_CUSTOM_FIELDS,
} from "./actions/updateCustomFieldsAction";
import { addMapsToStateAction } from "./actions/addMapsToStateAction";
import { addPolygonToMapAction } from "./actions/addPolygonToMapAction";
import { addPropertiesAction } from "./actions/addPropertiesAction";
import { addPropertiesWithCoordinatesToMapAction } from "./actions/addPropertiesWithCoordinatesToMapAction";
import { addPropertyToMapAction } from "./actions/addPropertyToMapAction";
import { changeMapOptionsAction } from "./actions/changeMapOptionsAction";
import { removePropertyFromMapAction } from "./actions/removePropertyFromMapAction";
import {
  UPDATE_STATE,
  MapsStateContextInterface,
  MapsStateTypesEnum,
  ADD_MAPS_TO_STATE,
  ADD_PROPERTY_TO_MAP,
  ADD_PROPERTIES_WITH_COORDINATES_ON_MAP,
  ADD_PROPERTIES,
  CHANGE_MAP_OPTIONS,
  REMOVE_PROPERTY_FROM_MAP,
  ADD_POLYGON_TO_MAP,
  REMOVE_ALL_POLYGONS_FROM_MAP,
} from "./MapsStateInterface";
import { removeAllPolygonsFromMapAction } from "./actions/removeAllPolygonsFromMapAction";
// import {
//   updateStateAction
// } from './actions/updateStateAction'

export type MapsStateAction =
  | UPDATE_STATE
  | ADD_MAPS_TO_STATE
  | ADD_PROPERTY_TO_MAP
  | CHANGE_MAP_OPTIONS
  | ADD_PROPERTIES_WITH_COORDINATES_ON_MAP
  | REMOVE_ALL_POLYGONS_FROM_MAP
  | REMOVE_PROPERTY_FROM_MAP
  | ADD_POLYGON_TO_MAP
  | UPDATE_CUSTOM_FIELDS
  | ADD_PROPERTIES;

function mapsStateReducer(
  state: MapsStateContextInterface,
  action: MapsStateAction
): MapsStateContextInterface {
  switch (action.type) {
    case MapsStateTypesEnum.ADD_MAPS_TO_STATE:
      return addMapsToStateAction(state, action);

    case MapsStateTypesEnum.ADD_PROPERTY_TO_MAP:
      return addPropertyToMapAction(state, action);

    case MapsStateTypesEnum.ADD_PROPERTIES_WITH_COORDINATES_ON_MAP:
      return addPropertiesWithCoordinatesToMapAction(state, action);

    case MapsStateTypesEnum.REMOVE_PROPERTY_FROM_MAP:
      return removePropertyFromMapAction(state, action);

    case MapsStateTypesEnum.REMOVE_ALL_POLYGONS_FROM_MAP:
      return removeAllPolygonsFromMapAction(state, action);

    case MapsStateTypesEnum.ADD_PROPERTIES:
      return addPropertiesAction(state, action);

    case MapsStateTypesEnum.ADD_POLYGON_TO_MAP:
      return addPolygonToMapAction(state, action);

    case MapsStateTypesEnum.CHANGE_MAP_OPTIONS:
      return changeMapOptionsAction(state, action);

    case MapsStateTypesEnum.UPDATE_CUSTOM_FIELDS:
      return updateCustomFieldsAction(state, action);

    default:
      return state;
  }
}

export default mapsStateReducer;
