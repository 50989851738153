import React from "react";
import { styled } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import { EqualizerRounded, LocalAtmRounded } from "@material-ui/icons";

enum BottomNavigationEnum {
  dashboard = "Dashboard",
  fluxoDeCaixa = "Fluxo de caixa",
  cobrancas = "Cobranças",
}

const CustomBottomNavigationMobile = styled(BottomNavigation)({
  position: "fixed",
  bottom: "0",
  width: "100%",
});

export const BottomNavigationMobile: React.FC = () => {
  const [value, setValue] = React.useState<BottomNavigationEnum>(
    BottomNavigationEnum.dashboard
  );

  const handleChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: BottomNavigationEnum
  ): void => {
    setValue(newValue);
  };

  return (
    <CustomBottomNavigationMobile value={value} onChange={handleChange}>
      <BottomNavigationAction
        label={BottomNavigationEnum.dashboard}
        value={BottomNavigationEnum.dashboard}
        icon={<EqualizerRounded />}
      />
      <BottomNavigationAction
        label={BottomNavigationEnum.fluxoDeCaixa}
        value={BottomNavigationEnum.fluxoDeCaixa}
        icon={<LocalAtmRounded />}
      />
      <BottomNavigationAction
        label={BottomNavigationEnum.cobrancas}
        value={BottomNavigationEnum.cobrancas}
        icon={<RestoreIcon />}
      />
    </CustomBottomNavigationMobile>
  );
};

export default BottomNavigation;
