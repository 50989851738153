import { FirestorePropertyInterface } from "../../../services/properties/getCustomFields";
import {
  MapsStateContextInterface,
  MapsStateTypesEnum,
} from "../MapsStateInterface";

export type UPDATE_CUSTOM_FIELDS = {
  type: MapsStateTypesEnum.UPDATE_CUSTOM_FIELDS;
  newState: FirestorePropertyInterface | undefined;
};

export function updateCustomFieldsAction(
  state: MapsStateContextInterface,
  action: UPDATE_CUSTOM_FIELDS
): MapsStateContextInterface {
  if (!action.newState) return state;

  const properties = state.properties.map((property) => {
    const response = Object.entries(
      action.newState || {}
    ).find(([key, value]) =>
      key === property.id_propriedades ? value : false
    );

    if (typeof response === "undefined") return property;

    const propertyTobeEdited = response[1];

    return {
      ...property,
      ...propertyTobeEdited,
    };
  });

  return {
    ...state,
    properties: properties,
  };
}
