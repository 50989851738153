import { MapsStateContextInterface } from "./MapsStateInterface";

export const mapsStateInitialState: MapsStateContextInterface = {
  map: undefined,
  maps: undefined,
  fetchingProperties: [],
  propertiesOnMap: [],
  propertiesWithCoordinatesOnMap: [],
  properties: [],
  lastPropertyAdded: undefined,
  polygons: {},
  mapOptions: {
    strokeColor: "#000",
    strokeWeight: 2,
    strokeOpacity: 8,
    fillOpacity: 35,
    useDisplayedOnly: true,
  },
};
