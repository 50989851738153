import { CompanyInterface } from "./getAllCompany";
import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosDelete,
} from "./../axiosService";

export const deleteCompany = async (
  id: string,
  token: Token
): Promise<CustomAxiosResponse<CompanyInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/company/${id}`;

    const response = await axiosDelete<CompanyInterface>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: `Empresa deletada!`,
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message
        ? error.message
        : "Ocorreu um erro, ao criar a empresa, tente novamente."
    );
  }
};
