import {
  LayoutContextInterface,
  LayoutTypesEnum,
  CONFIGURE_PAGE,
  HANDLE_PAGE_LOADING,
} from "./LayoutInterface";
import { configurePageAction } from "./actions/configurePageAction";
import { handlePageLoadingAction } from "./actions/handlePageLoadingAction";

export type LayoutAction = CONFIGURE_PAGE | HANDLE_PAGE_LOADING; // | OTHER_TYPE_GOES_HERE;

function layoutReducer(
  state: LayoutContextInterface,
  action: LayoutAction
): LayoutContextInterface {
  switch (action.type) {
    case LayoutTypesEnum.CONFIGURE_PAGE:
      return configurePageAction(state, action);

    case LayoutTypesEnum.HANDLE_PAGE_LOADING:
      return handlePageLoadingAction(state, action);

    default:
      return state;
  }
}

export default layoutReducer;
