export default {
  apiKey: "AIzaSyCCQkOQsSM1n9cCg34mgozSHsCq9rWahoY",
  authDomain: "plataforma-antarctica.firebaseapp.com",
  databaseURL: "https://plataforma-antarctica.firebaseio.com",
  projectId: "plataforma-antarctica",
  storageBucket: "plataforma-antarctica.appspot.com",
  messagingSenderId: "380335338635",
  appId: "1:380335338635:web:74752992439b853f31108e",
  measurementId: "G-P3C2ZX4JPV",
};
