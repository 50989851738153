/* eslint-disable @typescript-eslint/no-unused-vars */
import { HIDE_SNACKBAR, FeedbackContextInterface } from "../FeedbackInterface";

export function hideSnackbarAction(
  state: FeedbackContextInterface,
  action: HIDE_SNACKBAR
): FeedbackContextInterface {
  return {
    ...state,
    snackbar: undefined,
  };
}
