import { PlanaltoRouterPathsEnum } from "./CRoute";

export type NavigateToType = string;

export function navigateTo(where: PlanaltoRouterPathsEnum): NavigateToType {
  switch (where) {
    case PlanaltoRouterPathsEnum.root:
      return `${PlanaltoRouterPathsEnum.root}`;

    case PlanaltoRouterPathsEnum.propriedades:
      return `/${PlanaltoRouterPathsEnum.propriedades}`;

    case PlanaltoRouterPathsEnum.usuarios:
      return `/${PlanaltoRouterPathsEnum.usuarios}`;

    case PlanaltoRouterPathsEnum.empresas:
      return `/${PlanaltoRouterPathsEnum.empresas}`;

    case PlanaltoRouterPathsEnum.help:
      return `/${PlanaltoRouterPathsEnum.help}`;

    case PlanaltoRouterPathsEnum.login:
      return `/${PlanaltoRouterPathsEnum.login}`;

    case PlanaltoRouterPathsEnum.signup:
      return `/${PlanaltoRouterPathsEnum.signup}`;

    case PlanaltoRouterPathsEnum.other:
      return `/${PlanaltoRouterPathsEnum.other}`;

    default:
      return `${PlanaltoRouterPathsEnum.root}`;
  }
}
