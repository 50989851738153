import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PaperSection from "../../components/PaperSection";
import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import {
  CitiesAllowedType,
  UserInterface,
  UserRoleEnum,
} from "../../services/users/getAllUsers";
import {
  CityInterface,
  getAllCities,
} from "../../services/cities/getAllCities";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { VariantEnum } from "../../services/axiosService";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import CitiesAutocomplete from "./fields/CitiesAutocomplete";
import { updateUser } from "../../services/users/updateUser";
import { createUser } from "../../services/users/createUser";
import { useNavigate } from "react-router";
import EmpresaField from "./fields/EmpresaField";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";

interface UsuarioFormInterface {
  user: UserInterface | undefined;
}

const UsuarioForm: React.FC<UsuarioFormInterface> = ({ user }) => {
  const isEdit = user !== undefined;
  const navigate = useNavigate();
  const [cities, setCities] = useState<CityInterface[]>([]);
  const { firebaseAuth } = useContext(AuthContext);
  const [selectedCities, setSelectedCities] = useState<CityInterface[]>([]);
  const { control, handleSubmit, setValue, errors } = useForm<UserInterface>();
  const { dispatchFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    Object.entries(user || {}).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [setValue, user]);

  useEffect(() => {
    getAllCities(undefined)
      .then(({ data }) => setCities(data))
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      );
  }, [dispatchFeedback]);

  const onSubmit = (data: UserInterface) => {
    const cities_allowed: CitiesAllowedType = selectedCities.map(
      ({ id }) => id
    );

    const user: UserInterface = {
      ...data,
      cities_allowed,
    };

    isEdit &&
      updateUser(user, undefined)
        .then(() => navigate("/usuarios"))
        .catch(({ message }) =>
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant: VariantEnum.error,
          })
        );

    !isEdit &&
      createUser(user, firebaseAuth, undefined)
        .then(() => navigate("/usuarios"))
        .catch(({ message }) =>
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant: VariantEnum.error,
          })
        );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaperSection
          title={isEdit ? `Edição: ${user?.name}` : "Criar usuário"}
          first
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Controller
                render={({ ...props }) => (
                  <TextField
                    disabled
                    hidden
                    label="Id"
                    placeholder="Id"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                control={control}
                name="id"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    disabled
                    hidden
                    label="ID do firebase"
                    placeholder="firebase_id"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                control={control}
                name="firebase_id"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Nome do usuário"
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="name"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="email"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Senha"
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="password"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Telefone"
                    variant="outlined"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="phone"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Identidade"
                    variant="outlined"
                    fullWidth
                    error={!!errors.identity}
                    helperText={errors.identity?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="identity"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Ocupação"
                    variant="outlined"
                    fullWidth
                    error={!!errors.occupation}
                    helperText={errors.occupation?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="occupation"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Estrutura comercial"
                    variant="outlined"
                    fullWidth
                    error={!!errors.commercial_structure}
                    helperText={errors.commercial_structure?.message}
                    {...props}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="commercial_structure"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Gestão comercial"
                    variant="outlined"
                    fullWidth
                    error={!!errors.commercial_management}
                    helperText={errors.commercial_management?.message}
                    {...props}
                  />
                )}
                rules={{ required: "Esse campo é obrigatório!" }}
                control={control}
                error={!!errors.commercial_management}
                name="commercial_management"
              />

              <Controller
                render={({ ...props }) => (
                  <SelectField
                    variant="outlined"
                    {...props}
                    title="Permissão"
                    showEmptyOption={false}
                    name="role"
                    defaultValue={0}
                    helperText={errors.role?.message}
                    error={!!errors.role}
                    options={[
                      {
                        value: UserRoleEnum.MasterAdmin,
                        label: "Master Admin",
                      },
                      { value: UserRoleEnum.Admin, label: "Admin" },
                      { value: UserRoleEnum.RTV, label: "RTV" },
                      {
                        value: UserRoleEnum.GerenteComercial,
                        label: "Gerente Comercial",
                      },
                      {
                        value: UserRoleEnum.EstratégiaDeNegócio,
                        label: "Estratégia De Negócio",
                      },
                      {
                        value: UserRoleEnum.InteligênciaDeMercado,
                        label: "Inteligência De Mercado",
                      },
                    ]}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório" }}
                name="role"
                label="Role"
                placeholder="Role"
              />

              <Controller
                render={({ ...props }) => (
                  <SelectField
                    variant="outlined"
                    {...props}
                    title="Ativo ou não ativo"
                    showEmptyOption={false}
                    // onChange={handleVisualizationDateChange}
                    error={!!errors.active}
                    helperText={errors.active?.message}
                    options={[
                      {
                        value: false,
                        label: "Não ativo",
                      },
                      {
                        value: true,
                        label: "Ativo",
                      },
                    ]}
                  />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="active"
                label="Active"
                placeholder="Active"
              />

              <Controller
                render={({ ...props }) => (
                  <EmpresaField props={props} errorsForm={errors} />
                )}
                control={control}
                rules={{ required: "Esse campo é obrigatório!" }}
                name="id_company"
              />

              <Controller
                render={({ ...props }) => (
                  <CitiesAutocomplete
                    props={props}
                    cities={cities}
                    userCitiesAllowed={user?.cities_allowed || []}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                  />
                )}
                control={control}
                name="cities_allowed"
              />
            </Grid>

            <Grid justify="flex-end" item xs={12} md={12}>
              <Button type="submit" color="primary" variant="contained">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </PaperSection>
      </form>
    </>
  );
};

export default UsuarioForm;
