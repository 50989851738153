import {
  REMOVE_PROPERTY_FROM_MAP,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function removePropertyFromMapAction(
  state: MapsStateContextInterface,
  action: REMOVE_PROPERTY_FROM_MAP
): MapsStateContextInterface {
  const entry = Object.entries(state.polygons).find(
    ([id]) => id === action.property.id_propriedades
  );

  if (entry !== undefined) {
    const obj = entry[1];
    obj.polygons.forEach((polygon) => polygon.setMap(null));
    obj.infoWindow.close();
  }

  return {
    ...state,
    propertiesOnMap: state.propertiesOnMap.filter(
      ({ id_propriedades }) =>
        id_propriedades !== action.property.id_propriedades
    ),
  };
}
