import { Color } from "@material-ui/lab";
import { VariantEnum } from "./../../services/axiosService";

interface SnackbarOptions {
  autoHideDuration?: number;
}

interface SnackbarInterface {
  message: string;
  variant?: Color;
  timestamp?: number;
  options?: SnackbarOptions;
}

export interface FeedbackContextInterface {
  snackbar?: SnackbarInterface;
}

export enum FeedbackTypesEnum {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
}

export type SHOW_SNACKBAR = {
  type: FeedbackTypesEnum.SHOW_SNACKBAR;
  message: string;
  options?: SnackbarOptions;
  variant?: VariantEnum;
};

export type HIDE_SNACKBAR = {
  type: FeedbackTypesEnum.HIDE_SNACKBAR;
};
