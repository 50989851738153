import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext/AuthContext";
import { UserRoleEnum } from "../services/users/getAllUsers";

interface MiddlewareInterface {
  rolesAllowed: UserRoleEnum[] | undefined;
}

const Middleware: React.FC<MiddlewareInterface> = ({
  children,
  rolesAllowed,
}) => {
  const { stateAuth } = useContext(AuthContext);

  const userRole = stateAuth.loggedUser?.role;

  if (userRole === undefined || rolesAllowed === undefined) return null;

  const isAdmin = userRole === UserRoleEnum.Admin;
  const isMasterAdmin = userRole === UserRoleEnum.MasterAdmin;
  const hasPermission = !!rolesAllowed.find((role) => role === userRole);
  const canAcess = isAdmin || isMasterAdmin || hasPermission;

  return canAcess ? <>{children}</> : null;
};

export default Middleware;
