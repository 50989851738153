import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../contexts/LayoutContext/LayoutInterface";
import MapsStateProvider from "../../contexts/MapsStateContext/MapsStateContext";
import Middleware from "../../middlewares/Middleware";
import { UserRoleEnum } from "../../services/users/getAllUsers";

interface PropriedadeCrudRootPageInterface {}

const PropriedadeCrudRootPage: React.FC<PropriedadeCrudRootPageInterface> = () => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: "Propriedade",
      },
    });
  }, [dispatchLayout]);

  return (
    <>
      <Middleware rolesAllowed={[UserRoleEnum.Admin, UserRoleEnum.MasterAdmin]}>
        <MapsStateProvider>
          <Outlet />
        </MapsStateProvider>
      </Middleware>
    </>
  );
};

export default PropriedadeCrudRootPage;
