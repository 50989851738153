import React, { useContext } from "react";
import GoogleMapReact, { MapOptions } from "google-map-react";
import { MapsStateContext } from "../../../contexts/MapsStateContext/MapsStateContext";
import { MapsStateTypesEnum } from "../../../contexts/MapsStateContext/MapsStateInterface";

interface MapBootstrapInterface {}

const MapBootstrap: React.FC<MapBootstrapInterface> = () => {
  const { stateMapsState, dispatchMapsState } = useContext(MapsStateContext);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleApiLoaded = (map, maps) => {
    dispatchMapsState({
      type: MapsStateTypesEnum.ADD_MAPS_TO_STATE,
      map,
      maps,
    });
  };

  const getMapOptions = (maps: GoogleMapReact.Maps): MapOptions => {
    stateMapsState.mapOptions.fillOpacity;
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: true,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      zoomControl: true,
      clickableIcons: false,
    };
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
        }}
        defaultCenter={{
          lat: -14.3887841322149,
          lng: -55.92840391842,
        }}
        options={getMapOptions}
        defaultZoom={6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </div>
  );
};

export default React.memo(MapBootstrap);
