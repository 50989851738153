import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { planaltoTheme } from "../placatusTheme";

interface ThemeProviderInterface {}

const ThemeProvider: React.FC<ThemeProviderInterface> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => planaltoTheme(prefersDarkMode), [
    prefersDarkMode,
  ]);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
