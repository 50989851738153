/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

interface HelpContainerInterface {
  svg?: string;
}

const FullScreenDiv = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  min-height: calc(90vh - 60px);
  position: fixed;
`;

const HelpContainer: React.FC<HelpContainerInterface> = () => {
  return <FullScreenDiv>{/* <img src={IconDoubt} /> */}</FullScreenDiv>;
};

export default HelpContainer;
