import React from "react";
import { MUITSDatatable } from "../../../components/MUITSDatatable";
import { MUIDataTableColumn } from "mui-datatables";
import { Button, Chip, IconButton } from "@material-ui/core";
import { Done, Edit, Error, Warning } from "@material-ui/icons";
import { UserInterface } from "../../../services/users/getAllUsers";
import { useNavigate } from "react-router";

interface TableUsuariosInterface {
  users: UserInterface[];
}

interface TableUsuariosColumn extends Omit<MUIDataTableColumn, "name"> {
  name: keyof UserInterface;
}

const TableUsuarios: React.FC<TableUsuariosInterface> = ({ users }) => {
  const navigate = useNavigate();

  const columns: TableUsuariosColumn[] = [
    {
      name: "firebase_id",
      label: "ID Firebase",
      options: { filter: false, searchable: false, display: false },
    },
    {
      name: "active",
      label: "Usuário ativo?",
      options: {
        filterType: "checkbox",
        searchable: true,
        display: true,
        hint: "Se o usuário pode ou não acessar a plataforma",
        customBodyRender: (value): JSX.Element => (
          <Chip
            label={value ? "Ativo" : "Inativo"}
            clickable={false}
            color={value ? "primary" : "secondary"}
            onDelete={() => {}}
            deleteIcon={value ? <Done /> : <Error />}
          />
        ),
      },
    },
    {
      name: "name",
      label: "Nome",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
      },
    },
    {
      name: "cities_allowed",
      label: "Qtd de municípios?",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
        hint: "Número de municípios que o usuário pode enxergar",
        customBodyRender: (value: number[]): JSX.Element =>
          value.length === 0 ? (
            <Chip
              label={"Nenhuma cidade"}
              clickable={false}
              color="secondary"
              onDelete={() => {}}
              deleteIcon={<Warning />}
            />
          ) : (
            <div>{value.length}</div>
          ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
      },
    },
    {
      name: "phone",
      label: "Telefone",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
      },
    },
    {
      name: "role",
      label: "Permissão",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
        hint: "Número de municípios que o usuário pode enxergar",
        customBodyRender: (value: number): JSX.Element => <div>{value}</div>,
      },
    },
    {
      name: "occupation",
      label: "Ocupação",
      options: {
        filterType: "textField",
        searchable: true,
        display: true,
      },
    },
    {
      name: "firebase_id",
      label: "Ações",
      options: {
        filter: false,
        searchable: false,
        download: false,
        customBodyRenderLite: () => (
          <>
            <IconButton>
              <Edit />
            </IconButton>
          </>
        ),
      },
    },
  ];

  return (
    <>
      {/* <pre>{JSON.stringify(users, undefined, 2)}</pre> */}
      <MUITSDatatable
        title="Tabela de propriedades"
        data={users}
        columns={columns}
        options={{
          fixedHeader: true,
          selectableRows: "none",
          rowHover: true,
          selectableRowsOnClick: false,
          download: false,
          customToolbar: () => (
            <Button onClick={() => navigate("/usuarios/novo")} size="small">
              Criar usuário
            </Button>
          ),
          onRowClick: (rowData) => {
            const UUID = rowData[0];
            const user = users.find(({ firebase_id }) => firebase_id === UUID);
            user && navigate(`/usuarios/${user.firebase_id}`);
          },
        }}
      />
    </>
  );
};

export default TableUsuarios;
