import {
  RemoteConfigKeys,
  CustomFieldsInterface,
} from "./../contexts/Firebase/RemoteConfig/FirebaseRemoteConfigInterfaces";
import { UserInterface, UserRoleEnum } from "../services/users/getAllUsers";

export const candEditField = (
  user: UserInterface,
  remoteConfigState?: CustomFieldsInterface,
  customField?: RemoteConfigKeys
): boolean => {
  // Check if has the key on the remote config
  if (remoteConfigState && customField) {
    // Check if the field on remote config is enable to edit
    if (remoteConfigState[customField] === false) return false;
  }

  switch (user.role) {
    case UserRoleEnum.Admin:
      return true;

    case UserRoleEnum.MasterAdmin:
      return true;

    case UserRoleEnum.RTV:
      return true;

    default:
      return false;
  }
};
