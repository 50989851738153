export enum LoadingType {
  indeterminate = "indeterminate",
  determinate = "determinate",
}

export interface LayoutContextInterface {
  title: string;
  isLoading: boolean;
  loadingType: LoadingType;
  value?: number;
}

export enum LayoutTypesEnum {
  CONFIGURE_PAGE,
  HANDLE_PAGE_LOADING,
}

export type CONFIGURE_PAGE = {
  type: LayoutTypesEnum.CONFIGURE_PAGE;
  page: Partial<LayoutContextInterface>;
};

export type HANDLE_PAGE_LOADING = {
  type: LayoutTypesEnum.HANDLE_PAGE_LOADING;
  isLoading: boolean;
  loadingType?: LoadingType;
  value?: number;
};
