import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "../axiosService";

export interface CompanyInterface {
  id: string;
  name: string;
  cnpj: string;
  createdAt: string;
}

/**
 * @param token
 */
export const getAllCompany = async (
  token: Token
): Promise<CustomAxiosResponse<CompanyInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/company`;

    const response = await axiosGET<CompanyInterface[]>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
