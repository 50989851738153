import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { UserRoleEnum } from "../../services/users/getAllUsers";
import PaperSection from "../../components/PaperSection";
import { getCitiesByIds } from "../../services/cities/getCitiesByIds";
import { VariantEnum } from "../../services/axiosService";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { CityInterface } from "../../services/cities/getAllCities";

interface DashboardPageInterface {}

const DashboardPage: React.FC<DashboardPageInterface> = () => {
  const { stateAuth } = useContext(AuthContext);
  const { dispatchFeedback } = useContext(FeedbackContext);
  const [cities, setCities] = useState<CityInterface[]>([]);
  const Initials = stateAuth.loggedUser?.name.substr(0, 2).toUpperCase();
  const user = stateAuth.loggedUser;

  useEffect(() => {
    user &&
      getCitiesByIds(user, undefined)
        .then(({ data }) => setCities(data))
        .catch(({ message }) =>
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant: VariantEnum.error,
          })
        );
  }, [dispatchFeedback, user]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              avatar={<Avatar aria-label="recipe">{Initials}</Avatar>}
              title={user?.name}
              subheader={
                user?.role !== undefined ? UserRoleEnum[user.role] : "Sem papel"
              }
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                <strong>Email:</strong> {user?.email}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <strong>Telefone:</strong> {user?.phone}
              </Typography>
            </CardContent>
          </Card>

          <PaperSection title="Municípios disponíveis">
            <List dense={true}>
              {cities.map((city, index) => (
                <ListItem divider key={index}>
                  <ListItemText inset={false} primary={city.name} />
                </ListItem>
              ))}
            </List>
          </PaperSection>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
