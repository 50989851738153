import React, { useCallback, useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext/AuthContext";
import { LayoutContext } from "../../../contexts/LayoutContext/LayoutContext";
import {
  LayoutTypesEnum,
  LoadingType,
} from "../../../contexts/LayoutContext/LayoutInterface";
import { MapsStateContext } from "../../../contexts/MapsStateContext/MapsStateContext";
import { MapsStateTypesEnum } from "../../../contexts/MapsStateContext/MapsStateInterface";
import { getPropCoordById } from "../../../services/properties/getPropCoordById";
import { FirestorePropertyInterface } from "../../../services/properties/getCustomFields";
import { getPropertiesByCityIds } from "../../../services/properties/getPropertiesByCityIds";
import Lazy from "lazy.js";
import { FirestoreContext } from "../../../contexts/FirestoreContext/FirestoreContext";
import firebase from "firebase";

interface MapHandleEffectsInterface {}

const MapHandleEffects: React.FC<MapHandleEffectsInterface> = () => {
  const { stateAuth } = useContext(AuthContext);
  const { stateMapsState, dispatchMapsState } = useContext(MapsStateContext);
  const { firestore } = useContext(FirestoreContext);
  const { dispatchLayout } = useContext(LayoutContext);

  const citiesAllowed = stateAuth.loggedUser?.cities_allowed;

  const watchFirestoreChanges = useCallback(
    async (firestore: firebase.firestore.Firestore) => {
      firestore
        .collection("company_custom_fields")
        .doc(stateAuth.loggedUser?.id_company)
        .onSnapshot((doc) => {
          const newState = doc.data() as FirestorePropertyInterface;
          dispatchMapsState({
            type: MapsStateTypesEnum.UPDATE_CUSTOM_FIELDS,
            newState,
          });
        });
    },
    [dispatchMapsState, stateAuth.loggedUser?.id_company]
  );

  useEffect(() => {
    if (!citiesAllowed || !firestore) return;
    dispatchLayout({
      type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
      isLoading: true,
    });

    getPropertiesByCityIds(citiesAllowed, undefined)
      .then(({ data }) => {
        dispatchMapsState({
          type: MapsStateTypesEnum.ADD_PROPERTIES,
          properties: data,
        });
        watchFirestoreChanges(firestore);
      })
      .catch(({ message }) => console.error(message))
      .finally(() =>
        dispatchLayout({
          type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
          isLoading: false,
        })
      );
  }, [
    citiesAllowed,
    dispatchLayout,
    dispatchMapsState,
    firestore,
    watchFirestoreChanges,
  ]);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
      isLoading: true,
      loadingType: LoadingType.determinate,
      value: 0,
    });

    let iteration = 10;
    const chunkSize =
      stateMapsState.propertiesWithCoordinatesOnMap.length / 10 < 1
        ? 1
        : stateMapsState.propertiesWithCoordinatesOnMap.length;

    Lazy(stateMapsState.propertiesWithCoordinatesOnMap)
      .chunk(chunkSize)
      .async(3000)
      .each((properties) => {
        properties.forEach((property, index) => {
          dispatchMapsState({
            type: MapsStateTypesEnum.ADD_POLYGON_TO_MAP,
            coordinates: property.coordinates,
            property: property,
            changeViewPort: index === 0 && iteration === 10,
            boundsZoom: 200,
          });
        });
        iteration += chunkSize === 1 ? 50 : 10;
        dispatchLayout({
          type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
          isLoading: true,
          loadingType: LoadingType.determinate,
          value: iteration,
        });
      })
      .onComplete(() =>
        dispatchLayout({
          type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
          isLoading: false,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateMapsState.map,
    stateMapsState.maps,
    stateMapsState.propertiesWithCoordinatesOnMap,
    stateMapsState.mapOptions,
  ]);

  useEffect(() => {
    const { map, maps, propertiesOnMap } = stateMapsState;

    if (map && maps && propertiesOnMap.length > 0) {
      const selectedProperty = propertiesOnMap[0];
      dispatchLayout({
        type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
        isLoading: true,
      });
      getPropCoordById(undefined, selectedProperty.id_propriedades)
        .then(({ data }): void => {
          dispatchMapsState({
            type: MapsStateTypesEnum.ADD_POLYGON_TO_MAP,
            coordinates: data.coordinates,
            property: selectedProperty,
          });
        })
        .finally(() =>
          dispatchLayout({
            type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
            isLoading: false,
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateMapsState.map,
    stateMapsState.maps,
    stateMapsState.propertiesOnMap,
    stateMapsState.mapOptions,
  ]);

  return null;
};

export default MapHandleEffects;
