import { TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Controller, DeepMap, FieldError, useForm } from "react-hook-form";
import DialogForm from "../../../components/DialogForm";
import SelectField from "../../../components/SelectField";
import { FeedbackContext } from "../../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../../services/axiosService";
import { createCompany } from "../../../services/company/createCompany";
import {
  CompanyInterface,
  getAllCompany,
} from "../../../services/company/getAllCompany";
import { UserInterface } from "../../../services/users/getAllUsers";

interface EmpresaFieldInterface {
  props: {
    onChange: (...event: unknown[]) => void;
    onBlur: () => void;
    value: unknown;
    name: string;
    ref: React.MutableRefObject<unknown>;
  };
  errorsForm: DeepMap<UserInterface, FieldError>;
}

const EmpresaField: React.FC<EmpresaFieldInterface> = ({
  props,
  errorsForm,
}) => {
  const [isCreateCompanyLoading, setIsCreateCompanyLoading] = useState<boolean>(
    false
  );
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const { control, handleSubmit, errors } = useForm<CompanyInterface>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { dispatchFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    getAllCompany(undefined)
      .then(({ data }) => setCompanies(data))
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      );
  }, [dispatchFeedback]);

  const companySubmitMethod = async (data: CompanyInterface) => {
    setIsCreateCompanyLoading(true);

    createCompany(data, undefined)
      .then(({ data, message, variant }) => {
        setCompanies([data, ...companies]);
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant,
        });
      })
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      )
      .finally(() => {
        setIsCreateCompanyLoading(false);
        setOpenAddDialog(false);
      });
  };

  return (
    <>
      <DialogForm
        open={openAddDialog}
        onDisagree={() => setOpenAddDialog(false)}
        contentTitle="Adicionar empresa"
        maxWidth="xs"
        handleSubmit={handleSubmit}
        submitMethod={companySubmitMethod}
        loading={isCreateCompanyLoading}
      >
        <Controller
          rules={{ required: "Campo obrigatório" }}
          render={({ ...props }) => (
            <TextField
              label="Nome da empresa"
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              {...props}
            />
          )}
          control={control}
          name="name"
        />
        <Controller
          rules={{ required: false }}
          render={({ ...props }) => (
            <TextField
              label="CNPJ da empresa"
              variant="outlined"
              error={!!errors.cnpj}
              helperText={errors.cnpj?.message}
              fullWidth
              {...props}
            />
          )}
          control={control}
          name="cnpj"
        />
      </DialogForm>

      <SelectField
        variant="outlined"
        title="Empresa"
        addNew={() => setOpenAddDialog(true)}
        showEmptyOption={false}
        error={!!errorsForm.id_company}
        helperText={errorsForm.id_company?.message}
        options={companies.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        {...props}
      />
    </>
  );
};

export default EmpresaField;
