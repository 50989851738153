import {
  OutlinedTextFieldProps,
  TextField as MaterialTextField,
} from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

const CustomMaterialTextField = styled(MaterialTextField)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none !important;
    `}
`;

interface CustomTextFieldInterface extends OutlinedTextFieldProps {
  hidden?: boolean;
}

const TextField: React.FC<CustomTextFieldInterface> = ({
  hidden,
  ...props
}) => {
  return (
    <CustomMaterialTextField
      hidden={hidden}
      {...props}
      variant={props.variant || "outlined"}
      fullWidth={props.fullWidth || true}
    />
  );
};

export default TextField;
