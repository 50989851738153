/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { Add } from "@material-ui/icons";

const CustomFormControl = styled(FormControl)`
  width: 100%;
`;

const SelectField = ({
  showEmptyOption = true,
  value,
  options = [],
  variant = "outlined",
  fullWidth = true,
  title = "",
  selectedValue = false,
  addNew = undefined,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [idSelect, setIdSelect] = useState(0); // <--------------(Like this).

  const handleChange = (event) => {
    setIdSelect(event.target.value);
  };

  return (
    <>
      <Box mb="2em">
        <FormControl fullWidth variant="outlined">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexGrow="1">
              <InputLabel id="demo-simple-select-outlined-label">
                {title}
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value || idSelect}
                onChange={handleChange}
                label={title}
                {...props}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {addNew && (
              <>
                <Box px={1}>
                  <Button onClick={() => addNew && addNew()} key="add-new">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                    >
                      <Add />
                      <strong>Adicionar novo</strong>
                    </Box>
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default SelectField;
