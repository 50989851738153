/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomAxiosResponse, VariantEnum } from "./axiosService";
// Import dependencies
import { AxiosRequestConfig } from "axios";
import { setup } from "axios-cache-adapter";
import localforage from "localforage";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import memoryDriver from "localforage-memoryStorageDriver";

export interface AxiosResponse<T = any> {
  data?: T;
  status?: number;
  statusText: string;
  headers?: any;
  config?: any;
}

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "antarctica",
});

const api = (expire: number) =>
  setup({
    cache: {
      maxAge: expire, // set cache time to 2 minutes
      exclude: { query: false }, // cache requests with query parameters
      store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    },
  });

export const axiosGetCache = async <T>(
  url: string,
  expire: number = 60 * 60 * 1000 * 2, // two hours
  config: AxiosRequestConfig = {}
): Promise<CustomAxiosResponse<T>> => {
  try {
    const expireFinal = process.env.NODE_ENV === "production" ? expire : expire;
    const response = await api(expireFinal).get<T>(url, config);

    return {
      ...response,
      message: "Sucesso!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error("Ocorreu um problema");
  }
};
