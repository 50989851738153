import {
  ADD_MAPS_TO_STATE,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function addMapsToStateAction(
  state: MapsStateContextInterface,
  action: ADD_MAPS_TO_STATE
): MapsStateContextInterface {
  return {
    ...state,
    map: action.map,
    maps: action.maps,
  };
}
