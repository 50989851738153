import React, { createContext, useReducer } from "react";
import Reducer from "./FirestoreReducer";
import { firestoreInitialState } from "./FirestoreInitializer";
import {
  FirestoreContextInterface,
  FirestoreAction,
} from "./FirestoreInterface";
import "firebase/firestore";
import firebase from "firebase";

export type FirestoreDispatchType = React.Dispatch<FirestoreAction>;

interface FirestoreProviderInterface {
  stateFirestore: FirestoreContextInterface;
  dispatchFirestore: FirestoreDispatchType;
  firestore: firebase.firestore.Firestore | undefined;
}

export const FirestoreContext = createContext<FirestoreProviderInterface>({
  stateFirestore: firestoreInitialState,
  dispatchFirestore: () => console.warn("FirestoreDispatch not ready"),
  firestore: undefined,
});

interface FirestoreProviderProps {
  firestore: firebase.firestore.Firestore;
}

const FirestoreProvider: React.FC<FirestoreProviderProps> = ({
  children,
  firestore,
}) => {
  const [stateFirestore, dispatchFirestore] = useReducer(
    Reducer,
    firestoreInitialState
  );

  const globals = {
    stateFirestore,
    dispatchFirestore,
    firestore,
  };

  return (
    <FirestoreContext.Provider value={globals}>
      {children}
    </FirestoreContext.Provider>
  );
};

export default FirestoreProvider;
