export function parseUnknown(value: unknown): string {
  switch (typeof value) {
    case "undefined":
      return "S/ Info";

    case "number":
      return value.toString() === "" ? "S/ Info" : value.toString();

    case "string":
      return value === "" ? "S/ Info" : value;

    default:
      return "S/ Info";
  }
}
