import { addPolygonsToMap } from "../../../helpers/addPolygonToMap";
import {
  ADD_POLYGON_TO_MAP,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function addPolygonToMapAction(
  state: MapsStateContextInterface,
  action: ADD_POLYGON_TO_MAP
): MapsStateContextInterface {
  const polygons = addPolygonsToMap(
    action.coordinates,
    state,
    state.map,
    "red",
    action.property,
    action.changeViewPort,
    action.boundsZoom
  );

  return {
    ...state,
    polygons: {
      ...state.polygons,
      [action.property.id_propriedades]: polygons,
    },
  };
}
