import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import PropriedadesPage from "../pages/propriedades/PropriedadesPage";
import PropriedadesRootPage from "../pages/propriedades/PropriedadesRootPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import DashboardRootPage from "../pages/dashboard/DashboardRootPage";
import PropriedadesMapsConfig from "../pages/propriedades/PropriedadesMapsConfig";
import UsuariosPage from "../pages/usuarios/UsuariosPage";
import UsuariosRootPage from "../pages/usuarios/UsuariosRootPage";
import EmpresaPage from "../pages/empresa/EmpresaPage";
import EmpresaRootPage from "../pages/empresa/EmpresaRootPage";
import NotFoundErrorPage from "../pages/errors/NotFoundErrorPage";
import HelpPage from "../pages/help/HelpPage";
import HelpRootPage from "../pages/help/HelpRootPage";
import LoginPage from "../pages/login/LoginPage";
import LoginRootPage from "../pages/login/LoginRootPage";
// import SignupPage from "../pages/signup/SignupPage";
// import SignupRootPage from "../pages/signup/SignupRootPage";
import RootPage from "../RootPage";
import CRoute, { PlanaltoRouterPathsEnum } from "./CRoute";
import PrivateRouter from "./PrivateRouter";
import UsuariosCrud from "../pages/usuarios/UsuariosCrud";
import PropriedadeCrudRootPage from "../pages/propriedadeCrud/PropriedadeCrudRootPage";
import PropriedadeCrudPage from "../pages/propriedadeCrud/PropriedadeCrudPage";

interface RouterInterface {}

const Router: React.FC<RouterInterface> = () => (
  <BrowserRouter>
    <Routes>
      <PrivateRouter RedirectPage={<LoginPage />}>
        <CRoute path={PlanaltoRouterPathsEnum.root} element={<RootPage />}>
          {/* Dashboard */}
          <CRoute
            path={PlanaltoRouterPathsEnum.root}
            element={<DashboardRootPage />}
          >
            <CRoute
              path={PlanaltoRouterPathsEnum.root}
              element={<DashboardPage />}
            />
          </CRoute>

          {/* Tabela de propriedades com mapa */}
          <CRoute
            path={PlanaltoRouterPathsEnum.propriedades}
            element={<PropriedadesRootPage />}
          >
            <CRoute
              path={PlanaltoRouterPathsEnum.configuracoes}
              element={<PropriedadesMapsConfig />}
            />
            <CRoute
              path={PlanaltoRouterPathsEnum.root}
              element={<PropriedadesPage />}
            />
          </CRoute>

          {/* Crud de propriedade */}
          <CRoute
            path={PlanaltoRouterPathsEnum.propriedade}
            element={<PropriedadeCrudRootPage />}
          >
            <CRoute
              path={PlanaltoRouterPathsEnum.propriedadeEdit}
              element={<PropriedadeCrudPage />}
            />
          </CRoute>

          {/* Tabela de usuários  */}
          <CRoute
            path={PlanaltoRouterPathsEnum.usuarios}
            element={<UsuariosRootPage />}
          >
            <CRoute
              path={PlanaltoRouterPathsEnum.usuariosEdit}
              element={<UsuariosCrud />}
            />
            <CRoute
              path={PlanaltoRouterPathsEnum.root}
              element={<UsuariosPage />}
            />
          </CRoute>

          {/* Tabela de empresas  */}
          <CRoute
            path={PlanaltoRouterPathsEnum.empresas}
            element={<EmpresaRootPage />}
          >
            {/* <CRoute
              path={PlanaltoRouterPathsEnum.usuariosEdit}
              element={<UsuariosCrud />}
            /> */}
            <CRoute
              path={PlanaltoRouterPathsEnum.root}
              element={<EmpresaPage />}
            />
          </CRoute>

          {/* Tela de ajuda */}
          <CRoute
            path={PlanaltoRouterPathsEnum.help}
            element={<HelpRootPage />}
          >
            <CRoute
              path={PlanaltoRouterPathsEnum.root}
              element={<HelpPage />}
            />
          </CRoute>
        </CRoute>
      </PrivateRouter>

      <CRoute path={PlanaltoRouterPathsEnum.login} element={<LoginRootPage />}>
        <CRoute path={PlanaltoRouterPathsEnum.root} element={<LoginPage />} />
      </CRoute>

      {/* <CRoute
        path={PlanaltoRouterPathsEnum.signup}
        element={<SignupRootPage />}
      >
        <CRoute path={PlanaltoRouterPathsEnum.root} element={<SignupPage />} />
      </CRoute> */}

      <CRoute
        path={PlanaltoRouterPathsEnum.other}
        element={<NotFoundErrorPage />}
      />
    </Routes>
  </BrowserRouter>
);

export default Router;
