/* eslint-disable react/prop-types */
import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

interface NotFoundFullScreenInterface {
  message: string;
  subtitle: string;
  svg?: string;
}

const FullScreenDiv = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  min-height: calc(90vh - 60px);
`;

const NotFoundFullScreen: React.FC<NotFoundFullScreenInterface> = ({
  message,
  subtitle,
}) => {
  return (
    <FullScreenDiv>
      <CircularProgress size={40} />
      <Box mt="34px" mb="8px">
        <Typography color="primary" variant="h4">
          {message}
        </Typography>
      </Box>
      <Typography variant="body1">{subtitle}</Typography>
    </FullScreenDiv>
  );
};

export default NotFoundFullScreen;
