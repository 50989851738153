import {
  HANDLE_PAGE_LOADING,
  LayoutContextInterface,
  LoadingType,
} from "../LayoutInterface";

export function handlePageLoadingAction(
  state: LayoutContextInterface,
  action: HANDLE_PAGE_LOADING
): LayoutContextInterface {
  return {
    ...state,
    isLoading: action.isLoading,
    value: action.value,
    loadingType: action.loadingType || LoadingType.indeterminate,
  };
}
