import React, { useContext } from "react";
import { MUITSDatatable } from "../../../components/MUITSDatatable";
import { MUIDataTableColumn } from "mui-datatables";
import { PropertyInterface } from "../../../services/properties/getProperties";
import { MapsStateContext } from "../../../contexts/MapsStateContext/MapsStateContext";
import RenderTableCropsAreas from "../helpers/RenderTableCropsAreas";
import { AuthContext } from "../../../contexts/AuthContext/AuthContext";
import { FirestoreContext } from "../../../contexts/FirestoreContext/FirestoreContext";
import { AgriculturaField } from "./fields/AgriculturaField";
import { PotencialField } from "./fields/PotencialField";
import { ProprietarioField } from "./fields/ProprietarioField";
import { PropriedadeFieldd } from "./fields/PropriedadeField";
import { PorcentagemField } from "./fields/PorcentagemField";
import { FlorestaField } from "./fields/FlorestaField";
import { RankField } from "./fields/Rank";
import { SegmentacaoField } from "./fields/SegmentacaoField";
import { OperadorField } from "./fields/OperadorField";
import { PastagemField } from "./fields/PastagemField";
import { NomeContatoField } from "./fields/NomeContato";
import { TelefoneContatoField } from "./fields/TelefoneContato";
import { PotExploradoField } from "./fields/PotExplorado";
import { PotNaoExploradoField } from "./fields/PotNaoExplorado";
import RenderCustomToolbarSelect from "./tableOptions/RenderCustomToolbarSelect";
import { onDownload } from "./tableOptions/OnDownload";

interface TablePropriedadesInterface {}

export interface TablePropriedadesColumn
  extends Omit<MUIDataTableColumn, "name"> {
  name: keyof PropertyInterface;
}

type SortType = keyof PropertyInterface;
const sortOrderName: SortType = "agricultura";

const TablePropriedades: React.FC<TablePropriedadesInterface> = () => {
  const { stateMapsState } = useContext(MapsStateContext);
  const { stateAuth } = useContext(AuthContext);
  const { firestore } = useContext(FirestoreContext);

  const user = stateAuth.loggedUser;
  if (!firestore || !user || !stateMapsState) return null;

  const columns: TablePropriedadesColumn[] = [
    {
      // ==============================================
      //
      // WARNING:
      // DO NOT REMOTE THIS COLUMN FROM THE FIRST ORDER
      // THE OTHER FIELDS DEPENDS THIS TO GET REFERENCE
      // ID.
      //
      // ==============================================
      name: "id_propriedades",
      label: "ID Propriedade",
      options: {
        filter: false,
        searchable: true,
        display: false,
        download: true,
      },
    },
    {
      name: "floresta",
      label: "Floresta",
      options: {
        filter: false,
        filterType: "multiselect",
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <FlorestaField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "rank",
      label: "Rank",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <RankField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "porcentagem",
      label: "Porcentagem",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PorcentagemField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "propriedade",
      label: "Propriedade",
      options: {
        filter: true,
        filterType: "textField",
        searchable: true,
        display: true,
        hint: "Nome da fazenda",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PropriedadeFieldd
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "proprietario",
      label: "Proprietário",
      options: {
        filter: true,
        filterType: "textField",
        searchable: true,
        display: true,
        hint: "Nome do proprietário (pode haver mais de um)",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <ProprietarioField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "potencial",
      label: "Potencial",
      options: {
        filter: false,
        searchable: true,
        display: false,
        hint:
          "Potencial de vendas no cliente calculado em função da área de cultivos, índice tecnológico do cliente e potencial de consumos do hectare de cada cultivo na região",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PotencialField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "agricultura",
      label: "Agricultura",
      options: {
        filter: false,
        searchable: true,
        display: true,
        hint:
          "Área total da fazenda destinada à agricultura. Pode ser aproveitada para safra e safrinha, por exemplo",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => {
          return (
            <AgriculturaField
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              firestore={firestore}
              user={user}
            />
          );
        },
      },
    },
    {
      name: "segmentacao",
      label: "Segmentação",
      options: {
        filter: true,
        filterType: "multiselect",
        searchable: true,
        display: true,
        hint:
          "Como é feito o acesso? Quem é responsável pela venda (próprio vendedor, representante, canal, cooperativa, etc). Utilizar os valores que você mesmo cadastrou",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <SegmentacaoField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "municipio",
      label: "Município",
      options: {
        filterType: "dropdown",
        searchable: true,
        display: true,
      },
    },
    {
      name: "operador",
      label: "Operador",
      options: {
        filter: true,
        filterType: "multiselect",
        searchable: true,
        display: true,
        hint: "Nome de quem opera a fazenda (arrendatário, sócio, etc)",
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <OperadorField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "pastagem",
      label: "Pastagem",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PastagemField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "nomeContato",
      label: "Nome Contato",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <NomeContatoField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "telefoneContato",
      label: "Telefone Contato",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <TelefoneContatoField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "pot_explorado",
      label: "Pot Explorado",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PotExploradoField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
    {
      name: "pot_nao_explorado",
      label: "Pot Nao Explorado",
      options: {
        filter: false,
        searchable: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <PotNaoExploradoField
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            firestore={firestore}
            user={user}
          />
        ),
      },
    },
  ];

  return stateMapsState.properties ? (
    <>
      <MUITSDatatable
        title="Tabela de propriedades"
        data={stateMapsState.properties}
        columns={columns}
        options={{
          setTableProps: () => ({ dense: true }),
          fixedHeader: true,
          rowHover: true,
          selectableRows: "multiple",
          selectableRowsOnClick: false,
          expandableRows: true,
          expandableRowsHeader: true,
          expandableRowsOnClick: false,
          onDownload: (buildHead, buildBody, columns, data) =>
            onDownload(
              buildHead,
              buildBody,
              columns,
              data,
              stateMapsState.properties
            ),
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <RenderCustomToolbarSelect
              selectedRows={selectedRows}
              displayData={displayData}
              setSelectedRows={setSelectedRows}
            />
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const property = stateMapsState.properties[rowMeta.dataIndex];
            return <RenderTableCropsAreas property={property} />;
          },
          downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: false,
              useDisplayedRowsOnly: false,
            },
            filename:
              new Date()
                .toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .split("/")
                .reverse()
                .join("-") +
              "_" +
              new Date()
                .toLocaleTimeString("pt-BR", {
                  minute: "2-digit",
                  hour: "2-digit",
                })
                .replace(":", "-") +
              "-antarctica.csv",
          },
          // setRowProps: (row, dataIndex) => {
          //   const idRow = stateMapsState.properties[dataIndex].id_propriedades;
          //   const isSelected = stateMapsState.propertiesOnMap.find(
          //     ({ id_propriedades }) => id_propriedades === idRow
          //   );

          //   return {
          //     style: {
          //       background: isSelected ? "rgba(63,76,103,0.25)" : "transparent",
          //     },
          //   };
          // },
          sortOrder: {
            name: sortOrderName,
            direction: "desc",
          },
        }}
      />
    </>
  ) : null;
};

export default React.memo(TablePropriedades);
