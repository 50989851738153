import { axiosGetCache } from "./../axiosCache";
import uniqBy from "lodash.uniqby";
import { PropertyInterface } from "./getProperties";
import { Token, CustomAxiosResponse, VariantEnum } from "../axiosService";

export type CoordinatesType = Array<Coordinate[]>;

export interface getPropsFromQueryStringInterface extends PropertyInterface {
  coordinates: CoordinatesType;
}

export interface ResponseInterface {
  nextPage: number;
  previousPage: number;
  lastPage: number;
  totalPage: number;
  totalItems: number;
  data: getPropsFromQueryStringInterface;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export const getPropsFromQueryString = async (
  token: Token,
  query: string,
  limit = 500
): Promise<CustomAxiosResponse<getPropsFromQueryStringInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/properties?${query}`;

    const endpointFirst = `${endpoint}&page=0&limit=${limit}`;
    const firstRequest = await axiosGetCache<ResponseInterface>(endpointFirst);

    const requestMap = Array.from(Array(firstRequest["data"].totalPage).keys());

    const promiseArr = requestMap.map((number) => {
      const endpointLocal = `${endpoint}&page=${number}&limit=${limit}`;
      return axiosGetCache<ResponseInterface>(endpointLocal);
    });

    const response = await Promise.all(promiseArr);
    const dataArr: getPropsFromQueryStringInterface[] = response.flatMap(
      (response) => response.data.data
    );

    type uniqByKeyType = keyof PropertyInterface;
    const uniqByKey: uniqByKeyType = "id_final";

    return {
      ...response,
      status: 200,
      statusText: "ok",
      headers: {},
      config: {},
      data: uniqBy(dataArr, uniqByKey),
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
