import { UserInterface } from "./../../services/users/getAllUsers";
import firebase from "firebase";
import { NavigateFunction } from "react-router";

export type FirebaseUser = firebase.User | null;
export type FirebaseAuth = firebase.auth.Auth | undefined;

export interface AuthContextInterface {
  loggedUser: UserInterface | undefined;
  firebaseUser: FirebaseUser;
}

export enum AuthTypesEnum {
  UPDATE_STATE,
  LOGOUT_USER,
  AUTHENTICATE_USER,
  SET_FIREBASE_USER,
}

export type UPDATE_STATE = {
  type: AuthTypesEnum.UPDATE_STATE;
  newState: AuthContextInterface;
};

export type AUTHENTICATE_USER = {
  type: AuthTypesEnum.AUTHENTICATE_USER;
  loggedUser: UserInterface;
  firebaseUser: FirebaseUser;
  navigate: NavigateFunction;
};

export type SET_FIREBASE_USER = {
  type: AuthTypesEnum.SET_FIREBASE_USER;
  firebaseUser: FirebaseUser;
};

export type LOGOUT_USER = {
  type: AuthTypesEnum.LOGOUT_USER;
};
