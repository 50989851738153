import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotFoundFullScreen from "../../components/NotFoundFullScreen/NotFoundFullScreen";
import { getAllUsers, UserInterface } from "../../services/users/getAllUsers";
import TableUsuarios from "./layouts/TableUsuarios";

interface UsuariosPageInterface {}

const CustomGrid = styled(Grid)`
  .MuiPaper-root {
    height: 100%;
  }
`;

const UsuariosPage: React.FC<UsuariosPageInterface> = () => {
  const [users, setUsers] = useState<UserInterface[]>([]);

  useEffect(() => {
    getAllUsers(undefined).then(({ data }) => setUsers(data));
  }, []);

  return (
    <>
      <CustomGrid container spacing={0}>
        <CustomGrid item xs={12} md={12}>
          {users.length > 0 ? (
            <TableUsuarios users={users} />
          ) : (
            <NotFoundFullScreen
              message="Carregando!"
              subtitle="Estamos trazendo os usuarios, aguarde!"
            />
          )}
        </CustomGrid>
      </CustomGrid>
    </>
  );
};

export default UsuariosPage;
