import React, { useContext, useEffect } from "react";
import { IndexedDB } from "react-indexed-db";
import { Outlet } from "react-router";
import { DBConfig } from "../../configs/DBConfig";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../contexts/LayoutContext/LayoutInterface";
import MapsStateProvider from "../../contexts/MapsStateContext/MapsStateContext";

interface CaixaRootPageInterface {}

const CaixaRootPage: React.FC<CaixaRootPageInterface> = () => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: "Propriedades",
      },
    });
  }, [dispatchLayout]);

  return (
    <IndexedDB {...DBConfig}>
      <MapsStateProvider>
        <Outlet />
      </MapsStateProvider>
    </IndexedDB>
  );
};

export default CaixaRootPage;
