import { UserInterface } from "./getAllUsers";
import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosPATCH,
} from "./../axiosService";

type UpdateUserInterface = Partial<
  Omit<UserInterface, "id" | "firebase_id" | "createdAt" | "updatedAt">
>;

export const updateUser = async (
  body: UserInterface,
  token: Token
): Promise<CustomAxiosResponse<void>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/user/${body.id}`;

    const userToBeSaved: UpdateUserInterface = body;

    const response = await axiosPATCH<void>(endpoint, userToBeSaved, token);

    return {
      ...response,
      data: undefined,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, ao carregar o usuário."
    );
  }
};
