import React, { createContext, useReducer } from "react";
import Reducer, { MapsStateAction } from "./MapsStateReducer";
import { mapsStateInitialState } from "./MapsStateInitializer";
import { MapsStateContextInterface } from "./MapsStateInterface";

export type MapsStateDispatchType = React.Dispatch<MapsStateAction>;

interface MapsStateProviderInterface {
  stateMapsState: MapsStateContextInterface;
  dispatchMapsState: MapsStateDispatchType;
}

export const MapsStateContext = createContext<MapsStateProviderInterface>({
  stateMapsState: mapsStateInitialState,
  dispatchMapsState: () => console.warn("MapsStateDispatch not ready"),
});

const MapsStateProvider: React.FC = ({ children }) => {
  const [stateMapsState, dispatchMapsState] = useReducer(
    Reducer,
    mapsStateInitialState
  );

  const globals = {
    stateMapsState,
    dispatchMapsState,
  };

  return (
    <MapsStateContext.Provider value={globals}>
      {children}
    </MapsStateContext.Provider>
  );
};

export default MapsStateProvider;
