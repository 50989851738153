import { PropertyInterface } from "./../../../../services/properties/getProperties";
import { TablePropriedadesColumn } from "./../TablePropriedades";

interface BodyInterface {
  index: number;
  data: Array<number | null | string>;
}

export function onDownload(
  buildHead: (columns: Partial<TablePropriedadesColumn>) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildBody: (data: any[]) => string,
  columns: Partial<TablePropriedadesColumn>,
  data: BodyInterface[],
  properties: PropertyInterface[]
): string | boolean {
  const buildedArr = buildBody(data).split("\n");
  const buildedHead = buildHead(columns);
  const headerWithoutBreakline = buildedHead.substr(0, buildedHead.length - 2);
  const formattedHeader = `${headerWithoutBreakline},Safra (ano),Cultura,Área`;

  const bla = buildedArr.reduce((acc, val) => {
    const id = val.split(",")[0].replaceAll('"', "");
    const property = properties.find(
      ({ id_propriedades }) => id_propriedades === id
    );

    const emptyCommas = new Array(Object.keys(columns).length - 1).join('"",');
    const propertyBody = data.find((teste) => teste.data[0] === id);
    const repetableBuildedBody = propertyBody
      ? buildBody([propertyBody])
      : emptyCommas;

    const crops = property?.cropsAreas.reduce((accCrop, valCrop) => {
      const emptyCommas = repetableBuildedBody;
      return (accCrop += `${emptyCommas},${valCrop.crop_years || ""},${
        valCrop.crops.name || ""
      },${valCrop.area || ""},\n`);
    }, "");

    return (acc += crops || val);
  }, "");

  return `
    ${formattedHeader}
    ${bla}
  `;
}
