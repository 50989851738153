import {
  CHANGE_MAP_OPTIONS,
  MapsStateContextInterface,
} from "../MapsStateInterface";

export function changeMapOptionsAction(
  state: MapsStateContextInterface,
  action: CHANGE_MAP_OPTIONS
): MapsStateContextInterface {
  return {
    ...state,
    mapOptions: {
      ...state.mapOptions,
      ...action.options,
    },
  };
}
