import React from "react";
import HubspotChat from "../../components/HubspotChat/HubspotChat";
import HelpContainer from "./HelpContainer";

interface HelpPageInterface {}

const HelpPage: React.FC<HelpPageInterface> = () => {
  return (
    <>
      <HelpContainer />
      <HubspotChat />
    </>
  );
};

export default HelpPage;
