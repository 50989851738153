import React from "react";

interface NotFoundErrorPageInterface {}

const NotFoundErrorPage: React.FC<NotFoundErrorPageInterface> = () => {
  return (
    <h1>
      Página não encontrada.
      {/* <Link redirect={PlanaltoRouterPathsEnum.dashboard} /> */}
    </h1>
  );
};

export default NotFoundErrorPage;
