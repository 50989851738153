import { IdCompanyType } from "./../../../services/users/getAllUsers";
import { PropertyId } from "./../../../services/properties/getProperties";
import firebase from "firebase";

interface UpdateFirestoreCollectionInterface<T> {
  firestore: firebase.firestore.Firestore;
  propriedades_id: PropertyId;
  updateValue: Partial<T>;
  idCompany: IdCompanyType;
}

interface ResponseInterface {
  success: boolean;
  message: string;
}

export const updateFirestoreCollection: <T>(
  props: UpdateFirestoreCollectionInterface<T>
) => Promise<ResponseInterface> = async ({
  firestore,
  propriedades_id,
  updateValue,
  idCompany,
}) => {
  try {
    const collection = "company_custom_fields";

    if (!collection)
      throw new Error(
        "Banco de dados não encontrado, " +
          "entre em contato com o administrador do sistema"
      );

    await firestore
      .collection(collection)
      .doc(idCompany)
      .set(
        {
          [propriedades_id]: updateValue,
        },
        { merge: true }
      );

    return {
      success: true,
      message: "Campo alterado com sucesso!",
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: "Ocorreu um erro, tente novamente.",
    };
  }
};
