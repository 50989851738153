import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from "../axiosService";

export interface CityInterface {
  id: number;
  name: string;
  state_id: number;
}

/**
 * @param token
 */
export const getAllCities = async (
  token: Token
): Promise<CustomAxiosResponse<CityInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/cities`;

    const response = await axiosGET<CityInterface[]>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
