import { Box, Button, Typography } from "@material-ui/core";
import { MapRounded } from "@material-ui/icons";
import { DisplayData } from "mui-datatables";
import React, { useContext } from "react";
import { FeedbackContext } from "../../../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../../../contexts/FeedbackContext/FeedbackInterface";
import { LayoutContext } from "../../../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../../../contexts/LayoutContext/LayoutInterface";
import { MapsStateContext } from "../../../../contexts/MapsStateContext/MapsStateContext";
import { MapsStateTypesEnum } from "../../../../contexts/MapsStateContext/MapsStateInterface";
import { VariantEnum } from "../../../../services/axiosService";
import { AuthContext } from "../../../../contexts/AuthContext/AuthContext";
// import { getCitiesByIds } from "../../../../services/cities/getCitiesByIds";
import {
  getPropsFromQueryString,
  getPropsFromQueryStringInterface,
} from "../../../../services/properties/getPropsFromQueryString";

interface RenderCustomToolbarSelectInterface {
  selectedRows: {
    data: Array<{ index: number; dataIndex: number }>;
    lookup: { [key: number]: boolean };
  };
  displayData: DisplayData;
  setSelectedRows?: (rows: number[]) => void;
}

const RenderCustomToolbarSelect: React.FC<RenderCustomToolbarSelectInterface> = ({
  selectedRows,
  displayData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedRows,
}) => {
  const { dispatchFeedback } = useContext(FeedbackContext);
  const { dispatchLayout } = useContext(LayoutContext);
  const { stateMapsState, dispatchMapsState } = useContext(MapsStateContext);
  const { stateAuth } = useContext(AuthContext);

  const polygonsOnMapQuantity: number = Object.keys(stateMapsState.polygons)
    .length;

  const clearMap = () => {
    dispatchMapsState({
      type: MapsStateTypesEnum.REMOVE_ALL_POLYGONS_FROM_MAP,
    });
  };

  const addPropertiesToMap = async () => {
    if (!stateAuth.loggedUser) return;

    try {
      const keyArr = "municipio";
      // const citiesResp = await getCitiesByIds(stateAuth.loggedUser, undefined);
      // const arr = citiesResp.data.map(({ name }) => name);
      // const citiesStr = arr.join(",");

      const selectedRowsIdsAr = selectedRows.data.map(({ dataIndex }) => {
        const property = displayData.find(
          (display) => display.dataIndex === dataIndex
        );
        // Get property ID
        return property?.data[0] as string;
      });

      const selectedRowsMunAr = selectedRows.data.map(({ dataIndex }) => {
        const property = displayData.find(
          (display) => display.dataIndex === dataIndex
        );
        return property?.data[9] as string;
      });

      const citiesStr = selectedRowsMunAr.filter((este: string, i: number) => selectedRowsMunAr.indexOf(este) === i)

      const encoded = encodeURI(keyArr + "=" + citiesStr);

      dispatchLayout({
        type: LayoutTypesEnum.HANDLE_PAGE_LOADING,
        isLoading: true,
      });

      getPropsFromQueryString(undefined, encoded)
        .then(({ data }) => {
          const selectedProperties = selectedRowsIdsAr.map((id) =>
            data.find(({ id_propriedades }) => id_propriedades === id)
          );

          const cleanedProperties = selectedProperties.filter(
            (entry) => entry !== undefined
          ) as getPropsFromQueryStringInterface[];

          dispatchMapsState({
            type: MapsStateTypesEnum.ADD_PROPERTIES_WITH_COORDINATES_ON_MAP,
            properties: cleanedProperties,
          });
        })
        .catch(({ message }) =>
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant: VariantEnum.error,
          })
        )
        .finally(() => {
          dispatchFeedback({
            type: FeedbackTypesEnum.HIDE_SNACKBAR,
          });
        });
    } catch (error) {
      alert("Aconteceu um problema ao renderizar as propriedades no mapa.");
    }
  };

  return (
    <Box ml="26px" display="flex" flexDirection="column">
      <Typography gutterBottom variant="caption">
        Carregar muitas propriedades pode causar lentidão
      </Typography>
      <Box display="flex" flexDirection="row">
        <Button
          onClick={addPropertiesToMap}
          startIcon={<MapRounded />}
          variant="contained"
          color="primary"
        >
          Renderizar propriedades no mapa
        </Button>
        {polygonsOnMapQuantity > 0 && (
          <Box ml={2}>
            <Button onClick={clearMap} variant="outlined" color="primary">
              Limpar mapa
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RenderCustomToolbarSelect;
