import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import PaperSection from "../../components/PaperSection";
import MapBootstrap from "./containers/MapBootstrap";

interface PropriedadesMapsConfigInterface {}

const CustomTableGrid = styled(Grid)`
  .MuiPaper-root {
    height: 100%;
    display: grid;
  }
`;

const PropriedadesMapsConfig: React.FC<PropriedadesMapsConfigInterface> = () => {
  // const { stateMapsState, dispatchMapsState } = useContext(MapsStateContext);

  return (
    <>
      <Grid container spacing={0} style={{ height: "100%" }}>
        <CustomTableGrid item xs={12} md={6} style={{ height: "100%" }}>
          <PaperSection title="Configurações de mapa"></PaperSection>
        </CustomTableGrid>
        <Grid item xs={12} md={6} style={{ height: "100%" }}>
          <MapBootstrap />
        </Grid>
      </Grid>
    </>
  );
};

export default PropriedadesMapsConfig;
