import { axiosGetCache } from "./../axiosCache";
import { PropertyInterface } from "./getProperties";
import { Token, CustomAxiosResponse, VariantEnum } from "./../axiosService";
import uniqBy from "lodash.uniqby";

/**
 * @param uuid
 * @param token
 */
export const getPropertiesByCityIds = async (
  ids: number[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _token: Token
): Promise<CustomAxiosResponse<PropertyInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_AWS_BACKEND}/properties/cities`;

    const promiseArr = ids.map((id) =>
      axiosGetCache<PropertyInterface[]>(`${endpoint}/${id}`)
    );

    const response = await Promise.all(promiseArr);
    // response[0].data
    const dataArr: PropertyInterface[] = response.flatMap(
      (response) => response.data
    );

    if (response.length === 0)
      throw new Error("Você não tem nenhuma propriedade para visualizar");

    type uniqByKeyType = keyof PropertyInterface;
    const uniqByKey: uniqByKeyType = "id_final";

    return {
      ...response,
      status: 200,
      statusText: "ok",
      headers: {},
      config: {},
      data: uniqBy(dataArr, uniqByKey), // remove duplicates
      message: "Pronto!",
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : "Ocorreu um erro, tente novamente."
    );
  }
};
