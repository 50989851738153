import React, { useContext } from "react";
import { EditableCell } from "../../../../components/MUITSDatatable";
import { HTMLElementInputTypeEnum } from "../../../../components/MUITSDatatable/MUITSDatatableInterface";
import { RemoteConfigKeys } from "../../../../contexts/Firebase/RemoteConfig/FirebaseRemoteConfigInterfaces";
import { FirebaseRemoteConfigContext } from "../../../../contexts/Firebase/RemoteConfig/FirebaseRemoteConfigProvider";
import { candEditField } from "../../../../middlewares/canEditField";
import { PropertyInterface } from "../../../../services/properties/getProperties";
import { PropriedadeFieldInterface } from "../../interfaces/PropriedadeFieldInterface";
import { updateFirestoreCollection } from "../../services/updateFirestoreCollection";
import { parseUnknown } from "./handleUnknownValue";

interface SegmentacaoFieldInterface extends PropriedadeFieldInterface {}

type SegmentacaoFieldType = Partial<PropertyInterface>;

export const SegmentacaoField: React.FC<SegmentacaoFieldInterface> = ({
  tableMeta,
  updateValue,
  value,
  firestore,
  user,
}): JSX.Element => {
  const id = tableMeta.rowData[0];
  const parsedValue = parseUnknown(value);
  const { customFields } = useContext(FirebaseRemoteConfigContext);

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    const updateValue: SegmentacaoFieldType = {
      segmentacao: valueToUpdate,
    };

    try {
      await updateFirestoreCollection({
        firestore,
        updateValue,
        propriedades_id: id,
        idCompany: user.id_company,
      });
    } catch ({ message }) {
      alert(message);
    }
  };

  return candEditField(user, customFields, RemoteConfigKeys.segmentacao) ? (
    <EditableCell
      type={HTMLElementInputTypeEnum.text}
      value={parsedValue}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: false,
      }}
    />
  ) : (
    <>{parsedValue}</>
  );
};

export default SegmentacaoField;
