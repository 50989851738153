import React from "react";
import {
  AccountBalanceWalletOutlined,
  BusinessOutlined,
  ContactSupportOutlined,
  HomeOutlined,
  HouseOutlined,
} from "@material-ui/icons";
import { PlanaltoRouterPathsEnum } from "../routes/CRoute";
import { navigateTo, NavigateToType } from "../routes/navigateTo";
import { UserRoleEnum } from "../services/users/getAllUsers";

export interface MenuItemInterface {
  slug: PlanaltoRouterPathsEnum;
  label: string;
  icon: JSX.Element;
  disabled: boolean;
  onClick?: () => void;
  redirectUrl?: NavigateToType;
  rolesAllowed?: UserRoleEnum[];
}

type MenuSectionType = MenuItemInterface[];

type MenuConfigType = MenuSectionType[];

export const MenuConfig: MenuConfigType = [
  [
    {
      label: "Inicio",
      slug: PlanaltoRouterPathsEnum.root,
      icon: <HomeOutlined />,
      redirectUrl: navigateTo(PlanaltoRouterPathsEnum.root),
      disabled: false,
      rolesAllowed: [
        UserRoleEnum.EstratégiaDeNegócio,
        UserRoleEnum.GerenteComercial,
        UserRoleEnum.InteligênciaDeMercado,
        UserRoleEnum.RTV,
      ],
    },
    {
      label: "Propriedades",
      slug: PlanaltoRouterPathsEnum.propriedades,
      icon: <HouseOutlined />,
      redirectUrl: navigateTo(PlanaltoRouterPathsEnum.propriedades),
      disabled: false,
      rolesAllowed: [
        UserRoleEnum.EstratégiaDeNegócio,
        UserRoleEnum.GerenteComercial,
        UserRoleEnum.InteligênciaDeMercado,
        UserRoleEnum.RTV,
      ],
    },
    {
      label: "Usuários",
      slug: PlanaltoRouterPathsEnum.usuarios,
      icon: <AccountBalanceWalletOutlined />,
      redirectUrl: navigateTo(PlanaltoRouterPathsEnum.usuarios),
      disabled: false,
      rolesAllowed: [UserRoleEnum.Admin, UserRoleEnum.MasterAdmin],
    },
    {
      label: "Empresas",
      slug: PlanaltoRouterPathsEnum.empresas,
      icon: <BusinessOutlined />,
      redirectUrl: navigateTo(PlanaltoRouterPathsEnum.empresas),
      disabled: false,
      rolesAllowed: [UserRoleEnum.Admin, UserRoleEnum.MasterAdmin],
    },
  ],
  [
    {
      label: "Ajuda",
      slug: PlanaltoRouterPathsEnum.help,
      icon: <ContactSupportOutlined />,
      redirectUrl: navigateTo(PlanaltoRouterPathsEnum.help),
      disabled: false,
      rolesAllowed: [
        UserRoleEnum.EstratégiaDeNegócio,
        UserRoleEnum.GerenteComercial,
        UserRoleEnum.InteligênciaDeMercado,
        UserRoleEnum.RTV,
      ],
    },
    // {
    //   label: "Sair",
    //   slug: PlanaltoRouterPathsEnum.logout,
    //   icon: <ExitToAppOutlined />,
    //   redirectUrl: navigateTo(PlanaltoRouterPathsEnum.logout),
    //   disabled: false,
    //   rolesAllowed: [
    //     UserRoleEnum.EstratégiaDeNegócio,
    //     UserRoleEnum.GerenteComercial,
    //     UserRoleEnum.InteligênciaDeMercado,
    //     UserRoleEnum.RTV,
    //   ],
    //   onClick: () => alert("logout"),
    // },
  ],
];
