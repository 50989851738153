import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Button,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { PersonOutlineRounded, LockOutlined } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../services/axiosService";
import { getUserByFirebaseId } from "../../services/users/getUserByFirebaseId";
import { AuthTypesEnum } from "../../contexts/AuthContext/AuthInterface";
import { navigateTo } from "../../routes/navigateTo";
import { PlanaltoRouterPathsEnum } from "../../routes/CRoute";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://source.unsplash.com/collection/4621363/agriculture)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// const FlexGrid = styled(Grid)`
//   display: flex !important;
//   flex-direction: column !important;
// `;

interface LoginPageInterface {}

interface FormData {
  email: string;
  password: string;
}

const LoginPage: React.FC<LoginPageInterface> = () => {
  const { firebaseAuth, dispatchAuth } = useContext(AuthContext);
  const { dispatchFeedback } = useContext(FeedbackContext);
  const { control, handleSubmit, formState } = useForm<FormData>();
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    try {
      const response = await firebaseAuth?.signInWithEmailAndPassword(
        email,
        password
      );

      if (!response || !response.user?.uid) {
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message: "Tivemos um problema para fazer login, tente novamente!",
          variant: VariantEnum.error,
        });
        return;
      }

      const { data } = await getUserByFirebaseId(undefined, response.user?.uid);

      dispatchAuth({
        type: AuthTypesEnum.AUTHENTICATE_USER,
        firebaseUser: response.user,
        loggedUser: data,
        navigate,
      });

      setTimeout(() => {
        navigate(navigateTo(PlanaltoRouterPathsEnum.root));
      }, 1000);
    } catch ({ message }) {
      dispatchFeedback({
        type: FeedbackTypesEnum.SHOW_SNACKBAR,
        message,
        variant: VariantEnum.error,
      });
    }
  };

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} justify="center">
          <Box
            // item
            // xs={12}
            // sm={8}
            // md={5}
            display="flex"
            flexDirection="column"
            component={Paper}
            // elevation={6}
            // square
            alignContent="center"
            minHeight="100vh"
            // justify="center"
            alignItems="center"
          >
            <Box display="flex" flexGrow={1}>
              <div className={classes.paper}>
                <Box mt={-4} mb={4} textAlign="center">
                  <Box mb={3}>
                    <img
                      style={{ borderRadius: "12px" }}
                      width="auto"
                      height="120px"
                      src="/logo.png"
                      alt="Planalto"
                    />
                  </Box>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ onChange }) => (
                      <TextField
                        id="signin-form-input-email"
                        data-testid="signin-form-input-login"
                        placeholder="Usuário"
                        name="email"
                        variant="outlined"
                        fullWidth
                        disabled={formState.isSubmitting}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineRounded color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  <Box my={-1}>
                    <Controller
                      control={control}
                      name="password"
                      render={({ onChange, value }) => (
                        <TextField
                          id="password"
                          value={value}
                          onChange={onChange}
                          disabled={formState.isSubmitting}
                          placeholder="Senha"
                          data-testid="login-input-password"
                          variant="outlined"
                          fullWidth
                          type="password"
                          required
                          autoComplete="current-password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOutlined color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>

                  {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formState.isSubmitting}
                    className={classes.submit}
                  >
                    {formState.isSubmitting ? (
                      <CircularProgress color="primary" size={20} />
                    ) : (
                      "Entrar"
                    )}
                  </Button>
                  <Grid container>
                    {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                  </Grid>
                </form>
              </div>
            </Box>
            {/* <Box display="flex" flexDirection="row" alignItems="baseline">
              <CustomLink
                color="textSecondary"
                target="_blank"
                href="https://site.ockpay.com.br/termos-e-condicoes"
              >
                Termos de Uso
              </CustomLink>
              <Box mx={1}> </Box>
              <CustomLink
                color="textSecondary"
                // size="small"
                target="_blank"
                href="https://site.ockpay.com.br/politica-de-privacidade"
              >
                Política de Privacidade
              </CustomLink>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
