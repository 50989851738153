import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../contexts/LayoutContext/LayoutInterface";
import { Box, Container } from "@material-ui/core";

interface HelpRootPageInterface {}

const HelpRootPage: React.FC<HelpRootPageInterface> = () => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: "Ajuda",
      },
    });
  }, [dispatchLayout]);

  return (
    <Container>
      <Box mt={3}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default HelpRootPage;
