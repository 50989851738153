import { Box, Container } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { MenuConfig } from "../../configs/MenuConfig";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { LayoutTypesEnum } from "../../contexts/LayoutContext/LayoutInterface";
import Middleware from "../../middlewares/Middleware";
import { PlanaltoRouterPathsEnum } from "../../routes/CRoute";

interface EmpresaRootPageInterface {}

const EmpresaRootPage: React.FC<EmpresaRootPageInterface> = () => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: "Empresas",
      },
    });
  }, [dispatchLayout]);

  const rolesAllowed = MenuConfig[0].find(
    ({ slug }) => slug === PlanaltoRouterPathsEnum.empresas
  )?.rolesAllowed;

  return (
    <Middleware rolesAllowed={rolesAllowed}>
      <Container>
        <Box mt={3}>
          <Outlet />
        </Box>
      </Container>
    </Middleware>
  );
};

export default EmpresaRootPage;
