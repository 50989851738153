import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import NotFoundFullScreen from "../../components/NotFoundFullScreen/NotFoundFullScreen";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { VariantEnum } from "../../services/axiosService";
import { getAllUsers, UserInterface } from "../../services/users/getAllUsers";
import UsuarioForm from "./UsuarioForm";

interface UsuariosCrudInterface {}

const CustomGrid = styled(Grid)`
  .MuiPaper-root {
    height: 100%;
  }
`;

const UsuariosCrud: React.FC<UsuariosCrudInterface> = () => {
  const { dispatchFeedback } = useContext(FeedbackContext);
  const { firebaseUUID } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserInterface | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    getAllUsers(undefined)
      .then(({ data, message, variant }) => {
        setUser(data.find(({ firebase_id }) => firebaseUUID === firebase_id));
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant,
        });
      })
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      )
      .finally(() => setIsLoading(false));
  }, [dispatchFeedback, firebaseUUID]);

  return (
    <>
      <CustomGrid container spacing={0}>
        <CustomGrid item xs={12} md={12}>
          {!isLoading ? (
            <UsuarioForm user={user} />
          ) : (
            <NotFoundFullScreen
              message="Carregando!"
              subtitle="Estamos trazendo o usuário, aguarde!"
            />
          )}
        </CustomGrid>
      </CustomGrid>
    </>
  );
};

export default UsuariosCrud;
