import React from "react";
import { PropertyInterface } from "../../../services/properties/getProperties";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

interface RenderTableCropsAreasInterface {
  property: PropertyInterface;
}

const RenderTableCropsAreas: React.FC<RenderTableCropsAreasInterface> = ({
  property,
}) => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={4}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Ano</TableCell>
                <TableCell align="right">Cultivo</TableCell>
                <TableCell align="right">Área (ha)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {property.cropsAreas.map((crop) => (
                <TableRow key={crop.crops.uid}>
                  <TableCell component="th" scope="row">
                    {crop.crop_years}
                  </TableCell>
                  <TableCell align="right">{crop.crops.name}</TableCell>
                  <TableCell align="right">{crop.area}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RenderTableCropsAreas;
