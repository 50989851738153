import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import NotFoundFullScreen from "../../components/NotFoundFullScreen/NotFoundFullScreen";
import { MapsStateContext } from "../../contexts/MapsStateContext/MapsStateContext";
import MapBootstrap from "./containers/MapBootstrap";
import MapHandleEffects from "./containers/MapHandleEffects";
import TablePropriedades from "./layouts/TablePropriedades";

interface PropriedadesPageInterface {}

const CustomTableGrid = styled(Grid)`
  .MuiPaper-root {
    height: 100%;
    display: grid;
  }
`;

const PropriedadesPage: React.FC<PropriedadesPageInterface> = () => {
  const { stateMapsState } = useContext(MapsStateContext);

  return (
    <>
      <Grid container spacing={0} style={{ height: "100%" }}>
        <CustomTableGrid item xs={12} md={6} style={{ height: "100%" }}>
          {stateMapsState.properties.length > 0 ? (
            <>
              <TablePropriedades />
            </>
          ) : (
            <NotFoundFullScreen
              message="Carregando!"
              subtitle="Estamos trazendo as propriedades, aguarde!"
            />
          )}
        </CustomTableGrid>
        <Grid item xs={12} md={6} style={{ height: "100%" }}>
          <MapBootstrap />
          <MapHandleEffects />
        </Grid>
      </Grid>
    </>
  );
};

export default PropriedadesPage;
