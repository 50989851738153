import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PaperSection from "../../components/PaperSection";
import TextField from "../../components/TextField";
import {
  CityInterface,
  getAllCities,
} from "../../services/cities/getAllCities";
import { FeedbackContext } from "../../contexts/FeedbackContext/FeedbackContext";
import { VariantEnum } from "../../services/axiosService";
import { FeedbackTypesEnum } from "../../contexts/FeedbackContext/FeedbackInterface";
import { PropertyInterface } from "../../services/properties/getProperties";
import { updateProperty } from "../../services/properties/updateProperty";
import { navigateTo } from "../../routes/navigateTo";
import { PlanaltoRouterPathsEnum } from "../../routes/CRoute";
import { useNavigate } from "react-router";

interface PropriedadeFormInterface {
  property: PropertyInterface;
}

const PropriedadeForm: React.FC<PropriedadeFormInterface> = ({ property }) => {
  const isEdit = property !== undefined;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCities] = useState<CityInterface[]>([]);
  const { control, handleSubmit, setValue } = useForm();
  const { dispatchFeedback } = useContext(FeedbackContext);
  const navigate = useNavigate();

  useEffect(() => {
    Object.entries(property || {}).forEach(([key, value]) =>
      setValue(key, value)
    );
  }, [setValue, property]);

  useEffect(() => {
    getAllCities(undefined)
      .then(({ data }) => setCities(data))
      .catch(({ message }) =>
        dispatchFeedback({
          type: FeedbackTypesEnum.SHOW_SNACKBAR,
          message,
          variant: VariantEnum.error,
        })
      );
  }, [dispatchFeedback]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (data: PropertyInterface) => {
    isEdit &&
      updateProperty(property, undefined)
        .then(({ message, variant }) => {
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant,
          });
          navigate(navigateTo(PlanaltoRouterPathsEnum.propriedades));
        })
        .catch(({ message }) =>
          dispatchFeedback({
            type: FeedbackTypesEnum.SHOW_SNACKBAR,
            message,
            variant: VariantEnum.error,
          })
        );

    // TODO:
    // !isEdit &&
    //   createUser(user, firebaseAuth, undefined)
    //     .then(() => navigate("/usuarios"))
    //     .catch(({ message }) =>
    //       dispatchFeedback({
    //         type: FeedbackTypesEnum.SHOW_SNACKBAR,
    //         message,
    //         variant: VariantEnum.error,
    //       })
    //     );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaperSection
          title={
            isEdit ? `Edição: ${property?.propriedade}` : "Nova propriedade"
          }
          first
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Propriedade"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.propriedade}
                control={control}
                name="propriedade"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Proprietário"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.proprietario}
                control={control}
                name="proprietario"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Potencial"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.potencial}
                control={control}
                name="potencial"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Nome do contato"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.potencial}
                control={control}
                name="qeqweqwe"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Agricultura"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.agricultura}
                control={control}
                name="agricultura"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Pastagem"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.pastagem}
                control={control}
                name="pastagem"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Segmento"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.segmentacao}
                control={control}
                name="segmento"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Operador"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.operador}
                control={control}
                name="operador"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Potencial explorado"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.pot_explorado}
                control={control}
                name="pot_explorado"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Potencial não explorado"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.pot_nao_explorado}
                control={control}
                name="pot_nao_explorado"
              />

              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Telefone do contato"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.telefoneContato}
                control={control}
                name="telefoneContato"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Município"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.municipio}
                control={control}
                name="municipio"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Floresta"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.floresta}
                control={control}
                name="floresta"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Rank"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.rank}
                control={control}
                name="rank"
              />
              <Controller
                render={({ ...props }) => (
                  <TextField
                    label="Porcentagem"
                    variant="outlined"
                    fullWidth
                    {...props}
                  />
                )}
                defaultValue={property.porcentagem}
                control={control}
                name="porcentagem"
              />
            </Grid>

            <Grid justify="flex-end" item xs={12} md={12}>
              <Button color="primary" variant="contained">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </PaperSection>
      </form>
    </>
  );
};

export default PropriedadeForm;
